import { Fragment, React, useEffect, useState ,useRef } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import SuccessMessage from '../../../components/CustomComponents/Notifications/SuccessMessage';
import ErrorMessage from '../../../components/CustomComponents/Notifications/ErrorMessage';

import { inputFieldsMeta } from './inputFieldsMeta';

import {
  callAPICreateNewEmployee, callAPIGetEmployeeDetails,
  callAPIUpdateEmployee
} from '../../../apiCalls/employeeApiCalls';
import Form  from '../../../components/CustomComponents/CreateEditForms/Form';

function CreateEditOperator() {

  const url =  window.location.href;
  const urlParams =  new URL(url);
  const mode = urlParams.searchParams.get("mode");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const formRef = useRef();

  useEffect(async ()=>{
    if(mode==="edit") {
     await getOperatorDetails();
    }
  },[]);

  const setExistingValuesForForm = async (data) => {
    const {name,surname} = data;
    formRef.current.setValue('name',name);
    formRef.current.setValue('surname', surname);
  }

  const getOperatorDetails = async () => {
    setIsLoading(true);

    try {
      const result = await callAPIGetEmployeeDetails({
        employeeId: urlParams.searchParams.get("operatorId")
      });

      if (result.status === 200) {
        await setExistingValuesForForm(result.data.data);
        setIsLoading(false);
        setIsError(false);
      }
      else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Operator detaylari yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Operator detaylari yüklenirken bir hata oluştu.');
    }
  }


  const createNewOperator = async (data) => {
    try {
      const resultCreateNewOperator = await callAPICreateNewEmployee(data);
      if (resultCreateNewOperator.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        formRef.current.resetForm();
        setSuccessMessage(`${data.name} ${data.surname} isimli operator başarıyla oluşturuldu.`);

      }
      else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Operator oluşturulurken bir hata oluştu. Hata detayı: ', resultCreateNewOperator.data.message);
      }
    }
    catch (err) {
      console.log(err);
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Operator oluşturulurken bir hata oluştu.');
    }
  }

  const updateOperator = async (data) => {
    try {
      const resultUpdatedEmployee = await callAPIUpdateEmployee({employeeId:urlParams.searchParams.get("operatorId")}, data);
      if (resultUpdatedEmployee.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${data.name} ${data.surname} isimli operator başarıyla güncellendi.`);
      }
      else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Operator güncellenirken bir hata oluştu. Hata detayı: ', resultUpdatedEmployee.data.message);
      }
    }
    catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Operator güncellenirken bir hata oluştu.');
    }
  }

  const handleSave = async (data) => {
    setIsLoading(true);

    let operatorData = {
      ...data,
    };

    if(mode==="edit") {
      updateOperator(operatorData);
    }
    else {
      createNewOperator(operatorData)
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Form ref={formRef} mode={mode} handleSave={handleSave} isLoading={isLoading}  title='Operator Oluştur' inputFields={inputFieldsMeta}/>
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError}/>
      <SuccessMessage close={() => setIsSuccess(false)} successMessage={successMessage} show={isSuccess}/>
      <Footer/>
    </DashboardLayout>

  );
}

export default CreateEditOperator;


