import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Custom Library
import { callAPIGetDriverDetails } from "apiCalls/driverApiCalls";
import { callAPIGetContractDetails } from "apiCalls/contractApiCalls";
import { callAPIGetVehicleDetails } from "apiCalls/vehicleApiCalls";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import LoaderSpinner from "../../../components/CustomComponents/Loader/LoaderSpinner";
import {
  callAPIListDocuments,
} from "../../../apiCalls/documentApiCalls";
import { generateDocumentsTableColumnsRowsData } from "./documentsDataTable";

function ListDocuments() {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [documentsData, setDocumentsData] = useState();
  const [title, setTitle] = useState("");

  const url = window.location.href;
  const urlParams = new URL(url);
  const entity = urlParams.searchParams.get("entity");
  const docType = urlParams.searchParams.get("docType");
  const entityId = urlParams.searchParams.get("entityId");

  const requestParams = {};
  if (entity) requestParams.entity = entity;
  if (docType) requestParams.docType = docType;
  if (entityId) requestParams.entityId = entityId;

  useEffect(async () => {
    await getDocuments();

    if (entity === "driverVisa" || entity === "driver") getDriverDetails();
    else if (entity === "contract") getContractDetails();
    else if (entity === "truck" || entity === "trailer" || entity === "towTruck")
      getVehicleDetails();
  }, []);

  const getDriverDetails = async () => {
    try {
      const driverDetailsResponse = await callAPIGetDriverDetails({ driverId: entityId });

      if (driverDetailsResponse.status === 200) {
        const { name, surname } = driverDetailsResponse.data.data;
        setIsError(false);
        let titleEntity = "";
        if (entity === "driverVisa") titleEntity = "Vizeler";
        setTitle(`Şoför: ${name} ${surname} ${titleEntity}`);
        setIsLoading(false);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Sürücü yüklenirken bir hata oluştu. Hata detayı: ", result.data.message);
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Sürücü yüklenirken bir hata oluştu.");
    }
  };

  const getContractDetails = async () => {
    try {
      const result = await callAPIGetContractDetails({ contractId: entityId });
      if (result.status === 200) {
        const { contractNo } = result.data.data[0];
        setIsError(false);
        setTitle(`Taşıma no: ${contractNo}`);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Taşıma bilgisi yüklenirken hata oluştu", result.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Taşıma bilgisi yüklenirken hata oluştu");
    }
  };

  const getVehicleDetails = async () => {
    try {
      const result = await callAPIGetVehicleDetails({ vehicleId: entityId });

      if (result.status === 200) {
        const { plateNumber } = result.data.data;
        setIsError(false);
        let titleEntity = "";
        if (entity === "truck") titleEntity = "TIR";
        else if (entity === "towTruck") titleEntity = "Dorse";
        else if (entity === "trailer") titleEntity = "Çekici";

        setTitle(`${titleEntity} - Plaka: ${plateNumber}`);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Araç bilgisi yüklenirken hata oluştu", result.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Araç bilgisi yüklenirken hata oluştu");
    }
  };

  const getDocuments = async () => {
    let tableRowsColumns;
    try {
      const result = await callAPIListDocuments(requestParams);
      if (result.status === 200) {
        tableRowsColumns = await generateDocumentsTableColumnsRowsData(result.data.data[0]);
        setDocumentsData(tableRowsColumns);
        setIsError(false);
        setIsLoading(false);
      } else {
        tableRowsColumns = await generateDocumentsTableColumnsRowsData([]);
        setDocumentsData(tableRowsColumns);
        setIsError(true);
        setErrorMessage(
          "Dokumanlar yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
        setIsLoading(false);
      }
    } catch (err) {
      tableRowsColumns = await generateDocumentsTableColumnsRowsData([]);
      setDocumentsData(tableRowsColumns);
      setIsError(true);
      setErrorMessage("Dokumanlar yüklenirken bir hata oluştu.");
      setIsLoading(false);
    }
  };

  // TODO: make header more meaningful - map entity and entityids
  // TODO: add filtering

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {`${title} - Dokuman Listesi`}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <DataTable
                    isSorted={false}
                    canSearch
                    pagination={{ variant: "gradient", color: "info" }}
                    table={documentsData}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {/* <Fab sx={{position: 'fixed',
        bottom: 100,
        right: 50}} aria-label="Add" color="success">
        <AddIcon/>
      </Fab> */}
      </MDBox>
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default ListDocuments;
