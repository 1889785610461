export const inputFieldsMeta = ({ documentTypes }) => [
  {
    fieldName: "plateNumber",
    errorMessage: `Plaka zorunlu alan.Lutfen doldurunuz.`,
    label: "Plaka",
    isRequired: true,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "brand",
    label: "Marka",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "year",
    errorMessage: `1950 ile ${new Date().getFullYear() + 1} arasinda bir yil giriniz`,
    label: "Model",
    type: "number",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "comment",
    label: "Yorum",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "documents",
    documentTypes,
    label: "Dosya Yükle",
    isRequired: false,
    isFullWidth: true,
    componentType: "uploadFile",
  },
];
