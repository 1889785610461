// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";
import ButtonCards from "components/CustomComponents/ButtonCards/ButtonCards";
import DataTable from "examples/Tables/DataTable";

import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorMessage from "components/CustomComponents/Notifications/ErrorMessage";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { callAPIGetRecentCompanyDriverVisasPerCountry } from "apiCalls/driverVisaApiCalls";
import { generateExpiringVisaNotificationTableColumnsRowsData } from "pages/Common/expiringVisaNotificationTableData";
import {
  callAPIGetLastForwarderContracts,
  callAPIGetLastOwnContracts,
} from "../../apiCalls/contractApiCalls";
import { generateOwnersContractTableColumnsRowsData } from "../Common/ownerContractDataTable";
import { generateForwardersContractTableColumnsRowsData } from "../Common/forwarderContractDataTable";
import { callAPIGetAllContractCountsByMonthLastYear } from "../../apiCalls/contractStatisticsApiCalls";
import { generateMonthlyChartData } from "../Common/contractChartDataSets";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import { callAPIGetExpiringVehicleDocs } from "../../apiCalls/vehicleApiCalls";
import {
  generateExpiringVehicleDocsDataNotificationTableColumnsRowsData
} from "../Common/expiringVehicleDocsTableData";

import TableCard from "components/TableCard";
import ChartCard from "components/ChartCard";

function Home() {

  const [ownContractTableData, setOwnContractTableData] = useState();
  const [forwarderContractTableData, setForwarderContractTableData] = useState();

  const [contractCountAnalysisData, setContractCountAnalysisData] = useState();
  const [expiredVisaTableData, setExpiredVisaTableData] = useState();
  const [soonExpiringVisaTableData, setSoonExpiringVisaTableData] = useState();
  const [expiredVehicleDocsData, setExpiredVehicleDocsData] = useState();
  const [soonExpiringVehicleDocsData,setSoonExpiringVehicleDocsData] = useState();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    await getOwnContractsForTable();
    await getForwarderContractsForTable();
    await getContractCountsByMonthLastYear();
    await getDriverVisasForExpiringNotification();
    await getExpiringVehicleDocsForNotification();
    await setIsLoading(false);
  }, []);


  const getDriverVisasForExpiringNotification = async () => {
    try {
      const result = await callAPIGetRecentCompanyDriverVisasPerCountry();

      if (result.status === 200) {
        // filter by remining dates

        const expiredVisas = result.data.data.filter((d) => d.remainingValidDays < 1);
        const soonExpiringVisas = result.data.data.filter(
          (d) => d.remainingValidDays < 30 && d.remainingValidDays > 0
        );

        const expiredVisasTableRowsColumns =
          await generateExpiringVisaNotificationTableColumnsRowsData(expiredVisas);

        const soonExpiringVisasTableRowsColumns =
          await generateExpiringVisaNotificationTableColumnsRowsData(
            soonExpiringVisas,
            "soonExpiring"
          );

        setExpiredVisaTableData(expiredVisasTableRowsColumns);
        setSoonExpiringVisaTableData(soonExpiringVisasTableRowsColumns);

        setIsError(false);
      } else {
        const emptyTableRowsColumns = await generateExpiringVisaNotificationTableColumnsRowsData(
          []
        );
        setExpiredVisaTableData(emptyTableRowsColumns);
        setSoonExpiringVisaTableData(emptyTableRowsColumns);
        setIsError(true);
        setErrorMessage(
          "Vize bildirimleri yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      const emptyTableRowsColumns = await generateExpiringVisaNotificationTableColumnsRowsData([]);
      setExpiredVisaTableData(emptyTableRowsColumns);
      setSoonExpiringVisaTableData(emptyTableRowsColumns);
      setIsError(true);
      setErrorMessage("Vize bildirimleri yüklenirken bir hata oluştu.");
      setIsLoading(false);
    }
  };

  const getExpiringVehicleDocsForNotification = async () => {
    try {
      const result = await callAPIGetExpiringVehicleDocs();

      if (result.status === 200) {

        const expiredDocs = result.data.data.filter((d) => d.remainingValidDays < 1);
        const soonExpiringDocs = result.data.data.filter(
          (d) => d.remainingValidDays < 30 && d.remainingValidDays > 0
        );

        const expiredDocsTableRowsColumns =
          await generateExpiringVehicleDocsDataNotificationTableColumnsRowsData(expiredDocs);

        const soonExpiringDocsTableRowsColumns =
          await generateExpiringVehicleDocsDataNotificationTableColumnsRowsData(
            soonExpiringDocs,
            "soonExpiring"
          );

        setExpiredVehicleDocsData(expiredDocsTableRowsColumns);
        setSoonExpiringVehicleDocsData(soonExpiringDocsTableRowsColumns);

        setIsError(false);
      } else {
        const emptyTableRowsColumns = await generateExpiringVehicleDocsDataNotificationTableColumnsRowsData(
          []
        );
        setExpiredVehicleDocsData(emptyTableRowsColumns);
        setSoonExpiringVehicleDocsData(emptyTableRowsColumns);
        setIsError(true);
        setErrorMessage(
          "Suresi dolmus arac evraklari bildirimleri yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      const emptyTableRowsColumns = await generateExpiringVehicleDocsDataNotificationTableColumnsRowsData([]);
      setExpiredVehicleDocsData(emptyTableRowsColumns);
      setSoonExpiringVehicleDocsData(emptyTableRowsColumns);
      setIsError(true);
      setErrorMessage(          "Suresi dolmus arac evraklari bildirimleri yüklenirken bir hata oluştu. Hata detayı: ",
      );
      setIsLoading(false);
    }
  };

  const getContractCountsByMonthLastYear = async () => {
    let chartData;
    try {
      const result = await callAPIGetAllContractCountsByMonthLastYear();
      if (result.status === 200) {
        chartData = await generateMonthlyChartData(result.data.data);
        setContractCountAnalysisData(chartData);
        setIsError(false);
      } else {
        chartData = await generateMonthlyChartData([]);
        setContractCountAnalysisData(chartData);

        setIsError(true);
        setErrorMessage(
          "Analizler yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      chartData = await generateMonthlyChartData([]);
      setContractCountAnalysisData(chartData);
      setIsError(true);
      setErrorMessage("Analizler yüklenirken bir hata oluştu. Hata detayı: ");
    }
  };

  const getOwnContractsForTable = async () => {
    let tableRowsColumns;
    try {
      const result = await callAPIGetLastOwnContracts();
      if (result.status === 200) {
        tableRowsColumns = await generateOwnersContractTableColumnsRowsData(result.data.data);
        setOwnContractTableData(tableRowsColumns);
        setIsError(false);
      } else {
        tableRowsColumns = await generateOwnersContractTableColumnsRowsData([]);
        setOwnContractTableData(tableRowsColumns);
        setIsError(true);
        setErrorMessage(
          "Özmal taşıma listesi yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      tableRowsColumns = await generateOwnersContractTableColumnsRowsData([]);
      setOwnContractTableData(tableRowsColumns);
      setIsError(true);
      setErrorMessage("Özmal taşıma listesi yüklenirken bir hata oluştu.");
    }
  };

  const getForwarderContractsForTable = async () => {
    let tableRowsColumns;
    try {
      const result = await callAPIGetLastForwarderContracts();
      if (result.status === 200) {
        tableRowsColumns = await generateForwardersContractTableColumnsRowsData(result.data.data);
        setForwarderContractTableData(tableRowsColumns);
        setIsError(false);
      } else {
        tableRowsColumns = await generateForwardersContractTableColumnsRowsData([]);
        setForwarderContractTableData(tableRowsColumns);
        setIsError(true);
        setErrorMessage(
          "Nakliyeci taşıma listesi yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      tableRowsColumns = await generateForwardersContractTableColumnsRowsData([]);
      setForwarderContractTableData(tableRowsColumns);
      setIsError(true);
      setErrorMessage("Nakliyeci taşıma listesi yüklenirken bir hata oluştu.");
    }
  };

  const homeButtons = [
    {
      title: "Nakliyeci Taşıma Kaydı Oluştur",
      description: "Yeni taşıma kaydı oluşturmak için tıklayın",
      icon: "assignment_two_tone_icon",
      link: "/create-edit-contract?ownTransport=false",
    },
    {
      title: "Özmal Taşıma Kaydı Oluştur",
      description: "Yeni taşıma kaydı oluşturmak için tıklayın",
      icon: "assignment_two_tone_icon",
      link: "/create-edit-contract?ownTransport=true",
    },
    {
      icon: "business_center_two_tone_icon",
      title: "Müşteri Oluştur",
      description: "Yeni müşteri oluşturmak için tıklayın",
      link: "/create-edit-customer",
    },
    {
      icon: "local_shipping_two_tone_icon",
      title: "Nakliyeci Oluştur",
      description: "Yeni nakliyeci oluşturmak için tıklayın",
      link: "/create-edit-forwarder",
    },
    {
      icon: "person_add_alt_icon",
      title: "Operator Oluştur",
      description: "Yeni operator oluşturmak için tıklayın",
      link: "/create-edit-operator",
    },
    {
      title: "Çekici Oluştur",
      description: "Yeni Çekici oluşturmak için tıklayın",
      icon: "rv_hookup_tone_icon",
      link: "/create-edit-vehicle?type=Çekici&entity=trailer",
    },
    {
      title: "Dorse Oluştur",
      description: "Yeni Dorse oluşturmak için tıklayın",
      icon: "agriculture_tone_icon",
      link: "/create-edit-vehicle?type=Dorse&entity=towTruck",
    },
    {
      title: "Şoför Oluştur",
      description: "Yeni Şoför oluşturmak için tıklayın",
      icon: "local_shipping_two_tone_icon",
      link: "/create-edit-driver",
    },
  ];

  const renderButtons = homeButtons.map(({ title, description, link, icon }) => (
    <Grid item xs={12} md={6} lg={3}>
      <MDBox mb={1.5}>
        <NavLink to={link}>
          <ButtonCards icon={icon} title={title} description={description} value="" />
        </NavLink>
      </MDBox>
    </Grid>
  ));

  const charts = [
    {
      color: "success",
      title: "Aylık Taşıma Sayıları",
      description:
        "Bu grafik her ay için yapılan toplam taşıma sayılarını göstermektedir.Grafik son 6 ayı baz almaktadır.",
      data: contractCountAnalysisData,
    },
  ];

  const renderStatisticCharts = charts.map(({ color, title, description, data }) => (
    <ChartCard
      key={title}
      color={color}
      title={title}
      description={description}
      data={data || {}}
    />
  ));

  const renderTables = (isFull, title, tableData) => (
    <TableCard
      isFull={isFull}
      title={title}
      tableData={tableData}
      isLoading={isLoading}
    />
  );

  const expiringVehicleDocsTables = [
    {
      title: "Suresi Dolan Araç Evraklari",
      tableData: expiredVehicleDocsData,
    },
    {
      title: "Gecerliligi Bitmek Uzere Olan Araç Evraklari",
      tableData: soonExpiringVehicleDocsData,
    },
  ];

  const renderExpiringVehicleDocsNotificationTables = () => {
    const isFullTable = !(
      expiredVehicleDocsData.rows.length > 0 && soonExpiringVehicleDocsData.rows.length > 0
    );
    return expiringVehicleDocsTables.map(({ title, tableData }) =>
      !isLoading && tableData.rows.length > 0 ? renderTables(isFullTable, title, tableData) : null
    );
  };

  const expiringVisaTables = [
    {
      title: "Vizesi Biten Şoförler",
      tableData: expiredVisaTableData,
    },
    {
      title: "Vize Bitişi Yaklaşan Şoförler",
      tableData: soonExpiringVisaTableData,
    },
  ];


  const renderExpiringVisaNotificationTables = () => {
    const isFullTable = !(
      expiredVisaTableData?.rows?.length > 0 && soonExpiringVisaTableData?.rows?.length > 0
    );
    return expiringVisaTables.map(({ title, tableData }) =>
      !isLoading && tableData.rows.length > 0 ? renderTables(isFullTable, title, tableData) : null
    );
  };


  const contractTables = [
    {
      title: "Nakliyeci Taşıma Kayıtları (Son 5)",
      type: "Nakliyeci",
      tableData: forwarderContractTableData,
    },
    {
      title: "Özmal Taşıma Kayıtları (Son 5)",
      type: "Özmal",
      tableData: ownContractTableData,
    },
  ];

  const renderContractTables = contractTables.map(({ title, tableData, type }) =>
    !isLoading && tableData.rows.length > 0 ? (
      renderTables(true, title, tableData)
    ) : (
      <>
        <Divider color="info" variant="fullWidth" />
        <MDBox sx={{ alignItems: "center" }} py={3} mt={3}>
          <MDTypography variant="button" color="text" fontWeight="medium">
            {`${type} taşıması bulunmamaktadir.`}
          </MDTypography>
        </MDBox>
      </>
    )
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!isLoading ? (
        <>
          <HomeWidgets shouldRender func={renderButtons} />
          <HomeWidgets
            shouldRender={
              expiredVisaTableData?.rows?.length > 0 || soonExpiringVisaTableData?.rows?.length > 0
            }
            func={renderExpiringVisaNotificationTables()}
          />
          <HomeWidgets
            shouldRender={
              expiredVehicleDocsData?.rows?.length > 0 ||
              soonExpiringVehicleDocsData?.rows?.length > 0
            }
            func={renderExpiringVehicleDocsNotificationTables()}
          />
          <HomeWidgets
            shouldRender={contractCountAnalysisData?.datasets?.data.length > 0}
            func={renderStatisticCharts}
          />
          <HomeWidgets shouldRender func={renderContractTables} />
        </>
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

const HomeWidgets = (props) => {
  const { func, shouldRender } = props;

  if (shouldRender) {
    return (
      <MDBox py={6}>
        <Grid container spacing={3}>
          {func}
        </Grid>
      </MDBox>
    );
  }
  return <></>;
};

export default Home;
