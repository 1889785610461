import { React, useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link } from "react-router-dom";

import { callAPIGetDocumentTypes } from "apiCalls/documentTypeApiCalls";
import { createDocument } from "pages/Common/documentActions";
import {
  callAPIGetDriverVisaDetails,
  callAPIUpdateDriverVisa,
  callAPICreateNewDriverVisa,
} from "apiCalls/driverVisaApiCalls";

import { callAPIGetDriverDetails } from "apiCalls/driverApiCalls";
import { inputFieldsMeta } from "./inputFieldsMeta.js";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import Form from "../../../components/CustomComponents/CreateEditForms/Form";

function CreateEditDriverVisa() {
  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const entity = "driverVisa";
  const formRef = useRef();

  const [inputFieldsMetaData, setInputFieldsMetaData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [driverDetails, setDriverDetails] = useState({ name: "", surname: "" });

  let existingDriverVisaDetails = {};
  const alreadySelectedDropdownValues = {};

  const getDropdownIndexesForExistingValues = async () => {
    const { country } = existingDriverVisaDetails;
    if (country) {
      alreadySelectedDropdownValues.country = country;
    }
  };

  const setExistingValuesForForm = async () => {
    for (let [key, value] of Object.entries(existingDriverVisaDetails)) {
      if (key === "validTo") {
        value = value ? value.split("T")[0] : null;
      }

      // If mode is copy , do not set arr date dept date id and contract no
      if (!(mode === "copy" && key === "id")) {
        if (value !== null || value !== "" || key === "createdAt" || key === "updatedAt") {
          formRef.current.setValue(key, value);
        }
      }
    }
  };

  let documentTypes = [];

  useEffect(async () => {
    documentTypes = await getDocumentTypes();

    if (mode === "edit" || mode === "copy") {
      existingDriverVisaDetails = await getDriverVisaDetails();
      getDropdownIndexesForExistingValues();
      setInputFieldsMetaData(inputFieldsMeta({ documentTypes, alreadySelectedDropdownValues }));
      await setExistingValuesForForm();
    }

    const driverId = urlParams.searchParams.get("driverId") || existingDriverVisaDetails.driverId;
    await getDriverDetails(driverId);

    setInputFieldsMetaData(inputFieldsMeta({ documentTypes }));

    setIsLoading(false);
  }, []);

  const getDriverDetails = async (driverId) => {
    setIsLoading(true);
    try {
      const driverDetailsRetrieved = await callAPIGetDriverDetails({ driverId });
      setDriverDetails({
        name: driverDetailsRetrieved.data.data.name,
        surname: driverDetailsRetrieved.data.data.surname,
      });
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Şoför detaylari yüklenirken bir hata oluştu.");
    }
  };

  const getDocumentTypes = async () => {
    setIsLoading(true);
    try {
      const documentTypeResponse = await callAPIGetDocumentTypes({ entity });
      if (documentTypeResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return documentTypeResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Dokuman cesitleri yüklenirken bir hata oluştu. Hata detayı: ",
        result.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Dokuman cesitleri yüklenirken bir hata oluştu.");
    }
  };

  const getDriverVisaDetails = async () => {
    const url = window.location.href;
    const urlParams = new URL(url);
    const id = urlParams.searchParams.get("visaId");
    setIsLoading(true);
    try {
      const driverVisaDetails = await callAPIGetDriverVisaDetails({ visaId: id });
      if (driverVisaDetails.status === 200) {
        setIsError(false);
        return driverVisaDetails.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Vize detaylari yüklenirken bir hata oluştu. Hata detayı: ",
        result.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Vize detaylari yüklenirken bir hata oluştu.");
    }
  };

  const handleSave = async (driverVisaData) => {
    setIsLoading(true);

    if (mode === "edit") {
      updateDriverVisa(driverVisaData);
    } else {
      createNewDriverVisa(driverVisaData);
    }
  };

  const updateDriverVisa = async (data) => {
    try {
      const resultUpdatedDriverVisa = await callAPIUpdateDriverVisa(data);
      if (resultUpdatedDriverVisa.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`Vize başarıyla güncellendi`);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Vize güncellenirken bir hata oluştu. Hata detayı: ", result.data.message);
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Vize güncellenirken bir hata oluştu.");
    }
  };

  const createNewDriverVisa = async (data) => {
    // spread document file and data
    let { documents, ...newDriverVisa } = data;
    const driverId = urlParams.searchParams.get("driverId");

    newDriverVisa.driverId = parseInt(driverId);
    // TODO: investigate why default values don't work
    if (data.validTo === "") newDriverVisa = { ...newDriverVisa, validTo: null };

    try {
      const resultCreateNewDriverVisa = await callAPICreateNewDriverVisa(newDriverVisa);
      if (resultCreateNewDriverVisa.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`Vize başarıyla oluşturuldu.`);
        if (documents?.length > 0) {
          const documentDetails = {
            documents,
            entity,
            entityId: resultCreateNewDriverVisa.data.data.id,
          };
          await createDocument(documentDetails);
          formRef.current.resetForm();
        }
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Vize oluşturulurken bir hata oluştu. Hata detayı: ", result.data.message);
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Vize oluşturulurken bir hata oluştu.");
    }
  };
  // TODO: Add vehicle details screen
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {inputFieldsMetaData ? (
        <Form
          ref={formRef}
          mode={mode}
          handleSave={handleSave}
          isLoading={isLoading}
          title={`${driverDetails?.name} ${driverDetails?.surname} - Vize ${
            mode === "edit" ? "Güncelle" : "Oluştur"
          }`}
          inputFields={inputFieldsMetaData}
        />
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Link to="/vehicle-details?5">
        <SuccessMessage
          close={() => setIsSuccess(false)}
          successMessage={successMessage}
          show={isSuccess}
        />
      </Link>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateEditDriverVisa;
