import MDSnackbar from "components/MDSnackbar";


function ErrorMessage ({errorMessage,show,close,errorDetails}) {
  return (
    <MDSnackbar
      color="error"
      icon="error_outline"
      title={errorMessage || 'Hata oluştu'}
      autoHideDuration={500}
      content={errorDetails}
      open={show}
      close={close}
    />
  )
}

export default ErrorMessage;
