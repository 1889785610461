// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Page components
import DetailsPageRecentContactUpdateHistoryTimeline from "components/CustomComponents/DetailsPageCards/DetailsPageRecentContactUpdateHistoryTimeline";
import DetailsPageInfoDetailsCard from "components/CustomComponents/DetailsPageCards/DetailsPageInfoDetailsCard";
import DetailsPageContactPeopleCard from "components/CustomComponents/DetailsPageCards/DetailsPageContactPeopleCard";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DetailsPageHeaders from "../../../components/CustomComponents/DetailsPageHeaders/DetailsPageHeaders";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";

// React

// API Calls
import { callAPIGetForwarderDetails } from "../../../apiCalls/forwarderApiCalls";
import { callAPIGetLast5ForwardersStatusUpdates } from "../../../apiCalls/forwarderStatusUpdatesApiCalls";
import { callAPIGetForwarderContacts } from "../../../apiCalls/forwarderContactsApiCalls";

function ForwarderDetails() {
  const [isError, setIsError] = useState(false);
  // const [forwarderId,setForwarderId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [forwarderDetails, setForwarderDetails] = useState({
    shortName: "",
    name: "",
    email: "",
    phone: "",
    taxId: "",
    country: "",
    city: "",
    address: "",
    website: "",
    taxOffice: "",
  });

  const [forwarderContacts, setForwarderContacts] = useState([]);

  const [forwarderStatusUpdates, setForwarderStatusUpdates] = useState([]);

  const paramsFromNavigation = useLocation();
  const forwarderId = paramsFromNavigation.search.replace("?", "");

  useEffect(() => {
    getForwarderDetails();
    getForwarderContacts();
    getLast5ForwarderStatusUpdates();
  }, []);

  const getLast5ForwarderStatusUpdates = async () => {
    try {
      const result = await callAPIGetLast5ForwardersStatusUpdates({ forwarderId });
      if (result.status === 200) {
        setForwarderStatusUpdates(result.data.data);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Nakliyeci görüşmeleri yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci görüşmeleri yüklenirken bir hata oluştu.");
    }
  };

  const getForwarderDetails = async () => {
    try {
      const result = await callAPIGetForwarderDetails({ forwarderId });
      if (result.status === 200) {
        setForwarderDetails(result.data.data);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Nakliyeci detaylari yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci detaylari yüklenirken bir hata oluştu.");
    }
  };

  const getForwarderContacts = async () => {
    try {
      const result = await callAPIGetForwarderContacts({ forwarderId });
      if (result.status === 200) {
        setForwarderContacts(result.data.data);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Nakliyeci kisi listesi yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci kisi listesi yüklenirken bir hata oluştu.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <DetailsPageHeaders
        linkForEdit={`/create-edit-forwarder?forwarderId=${forwarderDetails.id}&mode=edit`}
        icon="local_shipping_two_tone_icon"
        name={
          !isLoading && forwarderDetails.name === ""
            ? forwarderDetails.shortName
            : forwarderDetails.name
        }
        type="Nakliyeci"
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Nakliyeci Bilgileri"
                info={{
                  Isim: forwarderDetails.shortName,
                  Telefon: forwarderDetails.phone,
                  email: forwarderDetails.email,
                  adres: forwarderDetails.address,
                  ulke: `${forwarderDetails.country}  ${forwarderDetails.city}`,
                  website: forwarderDetails.website,
                  vergiDairesi: forwarderDetails.taxOffice,
                  vergiNo: forwarderDetails.taxId,
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <DetailsPageContactPeopleCard
                isLoading={isLoading}
                title="İrtibat Kişileri"
                contacts={forwarderContacts || null}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ ml: 32, mr: 1 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <DetailsPageRecentContactUpdateHistoryTimeline
                pathForNewItem="/create-edit-forwarder-status-updates"
                forwarderId={forwarderId}
                isLoading={isLoading}
                statusUpdates={forwarderStatusUpdates || null}
              />
            </Grid>
          </Grid>
        </MDBox>
      </DetailsPageHeaders>
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default ForwarderDetails;
