import React from 'react';
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Tooltip from "@mui/material/Tooltip";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FolderIcon from "@mui/icons-material/Folder";
import { handleDocumentDownload } from "./documentActions";
import DeleteRecordButton from "../../components/CustomComponents/TableIconButtons/DeleteRecordButton";
import DeleteDocumentButton from "../../components/CustomComponents/TableIconButtons/DeleteDocumentButton";

const renderTableLinkComponent = ({ path, searchParams, icon, altText }) => (
  <Tooltip title={altText}>
    <Link
      style={{ marginLeft: 20 }}
      to={{
        pathname: path,
        search: `${searchParams || ""}`,
      }}
    >
      {icon}
    </Link>
  </Tooltip>
);


export const commonActionsColumn = (parameters) => {
  const {
    id,
    entity,
    subEntity,
    isEditMode = true,
    isCopyMode = true,
    details = true,
    download = false,
    remove = false,
    removeDoc = false,
    documents = false,
    addVisa = false,
    listVisas = false,
  } = parameters;

  return (
    <MDTypography style={{ marginLeft: 5 }} component="a" href="#" variant="h5">
      {details &&
        renderTableLinkComponent({
          path: `/${entity}-details`,
          searchParams: id,
          altText: "Detaya Git",
          icon: <InfoIcon color="info" />,
        })}

      {isCopyMode &&
        renderTableLinkComponent({
          path: `/create-edit-${entity}?mode=copy&${entity}Id=${id}`,
          altText: "Kopyala",
          icon: <ContentCopyIcon color="info" />,
        })}

      {isEditMode &&
        renderTableLinkComponent({
          path: `/create-edit-${entity}?mode=edit&${entity}Id=${id}`,
          altText: "Düzenle",
          icon: <EditIcon color="info" />,
        })}

      {documents &&
        renderTableLinkComponent({
          path: `/list-documents?entityId=${id}&entity=${subEntity || entity}`,
          altText: "Evraklara Git",
          icon: <FilePresentIcon color="info" />,
        })}

      {download && (
        <Tooltip title="Dosyayi indir">
          <DownloadForOfflineIcon onClick={() => handleDocumentDownload(id)} color="info" />
        </Tooltip>
      )}

      {addVisa &&
        renderTableLinkComponent({
          path: `/create-edit-visa`,
          searchParams: `driverId=${id}`,
          altText: "Vize Ekle",
          icon: <PostAddIcon color="info" />,
        })}
      {listVisas &&
        renderTableLinkComponent({
          path: `/list-driver-visas`,
          searchParams: `driverId=${id}`,
          altText: "Vizeleri Listele",
          icon: <FolderIcon color="info" />,
        })}

      {removeDoc && (
        <DeleteDocumentButton
          id={id}
        />
      )}

      {remove && (
        <DeleteRecordButton
          serviceEntity={entity}
          id={id}
        />
      )}
    </MDTypography>
  );
};
