// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// @mui material components
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
//Link
import {Link} from "react-router-dom";

import { returnShortDateString } from '../../../../utils/dateUtils';


export const generateForwarderContractsInDueTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "Taşıma No",
      accessor: "contractNo",
      align: "left"
    },
    {
      Header: "Taşıma Tarihi",
      accessor: "contractDate",
      align: "left"
    },
    {
      Header: "Vade",
      accessor: "dueDays",
      align: "center",
    },
    {
      Header: "Fiyat",
      accessor: "price",
      align: "center",
    },
    {
      Header: "Nakliyeci",
      accessor: "forwarderName",
      align: "center",
    },
    {
      Header: "",
      accessor: "actions",
      align: "center"
    },

  ];

  let rows = [];

  data.forEach(d => {
    const {contractNo,forwarderInvoicePriceValue,forwarderInvoiceCurrency,dueDays,contractDate,forwarderName,forwarderId,id} = d;
    rows.push({
      contractNo,
      contractDate: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {contractDate && returnShortDateString(contractDate)}
        </MDTypography>
      ),
      forwarderName,
      dueDays: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {dueDays} gün
        </MDTypography>
      ),
      price: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {forwarderInvoicePriceValue} {forwarderInvoiceCurrency}
        </MDTypography>
      ),
      actions: (
        <MDTypography style={{marginLeft:5}}  component="a" href="#" variant="h5"  >
          <Link to={{
            pathname: '/contract-details',
            search: `${id}`
          }}>
            <InfoIcon color="info"/>
          </Link>
        </MDTypography>
      )
    });
  })

  return {
    rows,columns
  }
}
