
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

//Custom Library
import ErrorMessage from '../../../components/CustomComponents/Notifications/ErrorMessage';
import LoaderSpinner from '../../../components/CustomComponents/Loader/LoaderSpinner';
import { callAPIGetAllForwarderContracts, callAPIListDocuments } from '../../../apiCalls/documentApiCalls';
import { generateDriverVisasTableColumnsRowsData } from "./driverVisasDataTables";
import { callAPIGetDriverVisas } from "apiCalls/driverVisaApiCalls";


function ListDriverVisas() {
 
  const [isLoading,setIsLoading]= useState(true);
  const [errorMessage,setErrorMessage] = useState("");
  const [isError,setIsError] =useState(false);
  const [visaData,setVisaData] = useState();
  const [driverNameSurname,setDriverNameSurname] = useState("Vize Bulunmamaktadir");

  const url =  window.location.href;
  const urlParams =  new URL(url);
  const driverId = urlParams.searchParams.get("driverId");

    useEffect(async () => {
        await getDriverVisas(); 
    }, []);

  const getDriverVisas = async ()=> {
    let tableRowsColumns;
    try {
      const result = await callAPIGetDriverVisas({driverId:driverId});
      if (result.status === 200) {
        tableRowsColumns = await generateDriverVisasTableColumnsRowsData(result.data.data);
        setVisaData(tableRowsColumns);
        if(!result.data.data[0]) setDriverNameSurname("Vize Bulunmamaktadir")
        else setDriverNameSurname(`${result.data.data[0]?.driverName} ${result.data.data[0]?.driverSurname} `)
        setIsError(false);
        setIsLoading(false);
      }
      else {
        tableRowsColumns = await generateDriverVisasTableColumnsRowsData([]);
        setVisaData(tableRowsColumns);
        setIsError(true);
        setErrorMessage('Vizeler yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
        setIsLoading(false);
        }
    }
    catch (err) {
        console.log(err);
      tableRowsColumns = await generateDriverVisasTableColumnsRowsData([]);
      setVisaData(tableRowsColumns);
      setIsError(true);
      setErrorMessage('Vizeler yüklenirken bir hata oluştu.');
      setIsLoading(false);
    }
  }

  return (
    <DashboardLayout>
    <DashboardNavbar />
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
               {`${driverNameSurname} - Vizeler`} 
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              { isLoading ?
                <LoaderSpinner/>
                :(
                  <DataTable
                    isSorted={false}
                    canSearch={true}
                    pagination={ {variant: "gradient", color: "info" }}
                    table={visaData}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
    <ErrorMessage close={()=>setIsError(false)} errorMessage={errorMessage} show={isError} />
    <Footer />
  </DashboardLayout>

  );
}

export default ListDriverVisas;
