// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import DescriptionIcon from "@mui/icons-material/Description";

// Link
import { returnShortDateString } from "utils/dateUtils";
import { commonActionsColumn } from "pages/Common/commonActionsColumnsInTable";

export const generateDocumentsTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%",
    },
    {
      Header: "Kategori/Kayit",
      accessor: "entity",
      align: "left",
    },
    {
      Header: "Evrak Cesiti",
      accessor: "docType",
      align: "left",
    },
    {
      Header: "Dosya Adi",
      accessor: "documentName",
      align: "center",
    },
    {
      Header: "Dosya Adi",
      accessor: "expiryDate",
      align: "Bitis Tarihi",
    },

    {
      Header: "Eklenme Tarihi",
      accessor: "createdAt",
      align: "center",
    },
    {
      Header: "",
      accessor: "actions",
      align: "center",
    },
  ];

  const rows = [];

  data.forEach((d) => {
    const { expiryDate, docType, documentName, id, createdAt, documentOwnerAliasIdentifier } = d;

    const actionColumnParams = {
      id,
      entity: d.entity,
      isEditMode: false,
      isCopyMode: false,
      details: false,
      download: true,
      removeDoc: true,
      documents: false,
    };

    let { entity } = d;
    switch (entity) {
      case "truck":
        entity = "TIR";
        break;
      case "trailer":
        entity = "Çekici";
        break;
      case "driverVisa":
        entity = "Şoför Vizesi";
        break;
      case "towTruck":
        entity = "Dorse";
        break;
      case "contract":
        entity = "Taşıma";
        break;
      case "driver":
        entity = "Şoför";
        break;
      case "customer":
        entity = "Müşteri";
        break;
      case "forwarder":
        entity = "Nakliyeci";
        break;
    }

    rows.push({
      icon: <DescriptionIcon color="info" fontSize="medium" />,
      entity: documentOwnerAliasIdentifier ? `${entity} - ${documentOwnerAliasIdentifier}` : entity,
      docType,
      documentName: (
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {documentName}
        </MDTypography>
      ),
      expiryDate,
      createdAt: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {createdAt && returnShortDateString(createdAt)}
        </MDTypography>
      ),
      actions: commonActionsColumn(actionColumnParams),
    });
  });

  return {
    rows,
    columns,
  };
};
