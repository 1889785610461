// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { commonActionsColumn } from "./commonActionsColumnsInTable";
import { actionBaseColumns } from "./baseContractDataTable";

export const generateExpiringVehicleDocsDataNotificationTableColumnsRowsData = async (data, type) => {
  let columns = [
    {
      Header: "Araç Türü",
      accessor: "vehicleType",
      align: "center",
    },
    {
      Header: "Plaka",
      accessor: "plateNumber",
      align: "center",
    },
    {
      Header: "Belge",
      accessor: "docType",
      align: "center",
    },
  ];

  if (type === "soonExpiring")
    columns.push({
      Header: "Kalan Gün Sayisi",
      accessor: "remainingValidDays",
      align: "center",
    });

  columns = [...columns, ...actionBaseColumns];

  const rows = [];

  data.forEach((d, index) => {
    const { id, plateNumber, remainingValidDays, vehicleType, docType } = d;

    const actionColumnParams = {
      id,
      isEditMode: false,
      isCopyMode: false,
      details: false,
      addVisa: false,
      listVisas: false,
      removeDoc: true,
      download: true,
    };

    rows.push({
      vehicleType:vehicleType  === "trailer" ?  "Cekici" : "Dorse",
      plateNumber:plateNumber,
      docType:docType,
    });

    if (type === "soonExpiring") {
      rows[index] = {
        ...rows[index],
        remainingValidDays: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {remainingValidDays}
          </MDTypography>
        ),
      };
    }

    rows[index] = {
      ...rows[index],
      actions: commonActionsColumn(actionColumnParams),
    };
  });

  return {
    rows,
    columns,
  };
};
