import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SuccessMessage from '../Notifications/SuccessMessage';
import { ConfirmationModal } from "../Alert";
import ErrorMessage from '../Notifications/ErrorMessage';
import { callAPIRemoveDocument } from "../../../apiCalls/documentApiCalls";


const DeleteDocumentButton = ({ serviceEntity, id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleDelete = async () => {
    try {
      await callAPIRemoveDocument({
        fileId: id
      });
      setIsSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);

    } catch (error) {
      setIsError(true);
      setErrorMessage(`Kayıt silinirken bir hata oluştu. Hata detayı: ${error.message}`);
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Tooltip title="Sil">
        <DeleteIcon         style={{ marginLeft: 20 }}
 onClick={() => setIsModalOpen(true)} color="info"/>
      </Tooltip>
      <ConfirmationModal
        text="Kaydi silmek istediginizden emin misiniz?"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDelete}
      />
      <SuccessMessage
        close={() => setIsSuccess(false)}
        successMessage={`Dosya başarıyla silindi.`}
        show={isSuccess}
      />
      <ErrorMessage
        close={() => setIsError(false)}
        errorMessage={errorMessage}
        show={isError}
      />
    </>
  );
};

export default DeleteDocumentButton;
