import { React, useEffect, useState, useRef } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";
import { createDocument } from "pages/Common/documentActions";
import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";

import { inputFieldsMeta } from "./inputFieldsMeta";
import Form from "../../../components/CustomComponents/CreateEditForms/Form";
import { callAPIGetDocumentTypes } from "../../../apiCalls/documentTypeApiCalls";
import {
  callAPICreateNewDriver,
  callAPIGetDriverDetails,
  callAPIUpdateDriver,
} from "../../../apiCalls/driverApiCalls";
import { ENTITY_NAMES } from "constants/entityNames";

function CreateEditDriver() {
  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [inputFieldsMetaData, setInputFieldsMetaData] = useState(null);

  let existingDriverValues = {};

  const setExistingValuesForForm = async () => {
    for (let [key, value] of Object.entries(existingDriverValues)) {
      if (key === "birthday") {
        value = value ? value.split("T")[0] : null;
      }

      // If mode is copy , do not set birth date, id
      if (!(mode === "copy" && (key === "birthday" || key === "id"))) {
        if (value !== null || value !== "" || key === "createdAt" || key === "updatedAt") {
          formRef.current.setValue(key, value);
        }
      }
    }
  };

  let documentTypes = [];

  useEffect(async () => {
    documentTypes = await getDocumentTypes();

    if (mode === "edit" || mode === "copy") {
      existingDriverValues = await getDriverDetails();

      const alreadySelectedDropdownValues = {};
      if (existingDriverValues.country)
        alreadySelectedDropdownValues.country = existingDriverValues.country;

      setInputFieldsMetaData(inputFieldsMeta({ documentTypes, alreadySelectedDropdownValues }));
      await setExistingValuesForForm();

      setIsLoading(false);
    } else {
      setInputFieldsMetaData(inputFieldsMeta({ documentTypes }));
      setIsLoading(false);
    }
  }, []);

  const getDocumentTypes = async () => {
    setIsLoading(true);
    try {
      const documentTypeResponse = await callAPIGetDocumentTypes({ entity: ENTITY_NAMES.driver });
      if (documentTypeResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return documentTypeResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Dokuman cesitleri yüklenirken bir hata oluştu. Hata detayı: ",
        result.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Dokuman cesitleri yüklenirken bir hata oluştu.");
    }
  };

  const getDriverDetails = async () => {
    const id = urlParams.searchParams.get("driverId");
    try {
      const driverDetailsResponse = await callAPIGetDriverDetails({ driverId: id });
      if (driverDetailsResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return driverDetailsResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Surucu yüklenirken bir hata oluştu. Hata detayı: ", result.data.message);
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Surucu yüklenirken bir hata oluştu.");
    }
  };

  const createNewDriver = async (data) => {
    const { documents, ...newDriverData } = data;

    if (!newDriverData.birthday) {
      newDriverData.birthday = null;
    }

    try {
      const resultNewDriverCreate = await callAPICreateNewDriver(null, newDriverData);

      if (resultNewDriverCreate.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(
          `${resultNewDriverCreate.data.data.id} nolu surucu başarıyla oluşturuldu.`
        );

        if (documents?.length > 0) {
          const documentDetails = {
            documents,
            entity: ENTITY_NAMES.driver,
            entityId: resultNewDriverCreate.data.data.id,
          };

          await createDocument(documentDetails);
        }
        formRef.current.resetForm();
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Surucu oluşturulurken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Surucu oluşturulurken bir hata oluştu.");
    }
  };

  const updateDriver = async (data) => {
    try {
      const resultUpdatedDriver = await callAPIUpdateDriver(null, data);
      if (resultUpdatedDriver.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${data.id} nolu şoför başarıyla güncellendi.`);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Şoför güncellenirken bir hata oluştu. Hata detayı: ", result.data.message);
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Şoför güncellenirken bir hata oluştu.");
    }
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    const driverData = {
      ...data,
    };

    if (mode === "edit") {
      updateDriver(driverData);
    } else {
      createNewDriver(driverData);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {inputFieldsMetaData ? (
        <Form
          ref={formRef}
          mode={mode}
          handleSave={handleSave}
          isLoading={isLoading}
          title={`Şoför ${mode === "edit" ? "Güncelle" : "Oluştur"}`}
          inputFields={inputFieldsMetaData}
        />
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <SuccessMessage
        close={() => setIsSuccess(false)}
        successMessage={successMessage}
        show={isSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}
export default CreateEditDriver;
