// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { commonActionsColumn } from "./commonActionsColumnsInTable";
import { actionBaseColumns } from "./baseContractDataTable";
import { ENTITY_NAMES } from "constants/entityNames";

export const generateExpiringVisaNotificationTableColumnsRowsData = async (data, type) => {
  let columns = [
    {
      Header: "Şoför",
      accessor: "driver",
      align: "center",
    },
    {
      Header: "Ülke",
      accessor: "country",
      align: "center",
    },
  ];

  if (type === "soonExpiring")
    columns.push({
      Header: "Kalan Gün Sayisi",
      accessor: "remainingValidDays",
      align: "center",
    });

  columns = [...columns, ...actionBaseColumns];

  const rows = [];

  data.forEach((d, index) => {
    const { driverName, driverSurname, country, remainingValidDays, driverId } = d;

    const actionColumnParams = {
      id: driverId,
      entity: ENTITY_NAMES.driverVisa,
      isEditMode: false,
      isCopyMode: false,
      details: false,
      addVisa: true,
      listVisas: true
    };

    rows.push({
      driver: (
        <MDTypography
          component="a"
          href={`list-driver-visas?${driverId}`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {driverName} {driverSurname}
        </MDTypography>
      ),
      country: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {country}
        </MDTypography>
      ),
    });

    if (type === "soonExpiring") {
      rows[index] = {
        ...rows[index],
        remainingValidDays: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {remainingValidDays}
          </MDTypography>
        ),
      };
    }

    rows[index] = {
      ...rows[index],
      actions: commonActionsColumn(actionColumnParams),
    };
  });

  return {
    rows,
    columns,
  };
};
