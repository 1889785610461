// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// React
import { useEffect, useState } from "react";

// Data and API Calls
import { callAPIGetFreeBusyTrailerStatus } from "../../../apiCalls/vehicleApiCalls";
import { generateFreeBusyTrailerStatusColumnsRowsData } from "./freeBusyTrailerStatusDataTable";

// Custom Library
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import LoaderSpinner from "../../../components/CustomComponents/Loader/LoaderSpinner";

function ListFreeBusyTrailersStatus() {
  const [freeTrailersTableData, setFreeTrailersTableData] = useState();
  const [busyTrailersTableData, setBusyTrailersTableData] = useState();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getFreeBusyTrailerStatus();
  }, []);

  const getFreeBusyTrailerStatus = async () => {
    let freeTrailerTableRowsColumns;
    let busyTrailerTablwRowsColumns;

    try {
      const result = await callAPIGetFreeBusyTrailerStatus();

      if (result.status === 200) {
        const freeTrailers = result.data.data.filter((d) => d.isFree === true);
        const busyTrailers = result.data.data.filter((d) => d.isFree === false);

        freeTrailerTableRowsColumns = await generateFreeBusyTrailerStatusColumnsRowsData(
          freeTrailers,
          true
        );
        busyTrailerTablwRowsColumns = await generateFreeBusyTrailerStatusColumnsRowsData(
          busyTrailers,
          false
        );

        setFreeTrailersTableData(freeTrailerTableRowsColumns);
        setBusyTrailersTableData(busyTrailerTablwRowsColumns);

        setIsLoading(false);
        setIsError(false);
      } else {
        freeTrailerTableRowsColumns = await generateFreeBusyTrailerStatusColumnsRowsData([], true);
        busyTrailerTablwRowsColumns = await generateFreeBusyTrailerStatusColumnsRowsData([], false);

        setFreeTrailersTableData(freeTrailerTableRowsColumns);
        setBusyTrailersTableData(busyTrailerTablwRowsColumns);

        setIsError(true);
        setIsLoading(false);

        setErrorMessage(
          `Araç listesi yüklenirken bir hata oluştu. Hata detayı: ${result.data.message}`
        );
      }
    } catch (err) {
      freeTrailerTableRowsColumns = await generateFreeBusyTrailerStatusColumnsRowsData([], true);
      busyTrailerTablwRowsColumns = await generateFreeBusyTrailerStatusColumnsRowsData([], false);

      setFreeTrailersTableData(freeTrailerTableRowsColumns);
      setBusyTrailersTableData(busyTrailerTablwRowsColumns);

      setIsLoading(false);
      setIsError(true);
      setErrorMessage(`Araç listesi yüklenirken bir hata oluştu. Hata detayı: ${err}`);
    }
  };

  const freeBusyTables = [
    {
      title: "Müsait Olan Araç Listesi",
      tableData: freeTrailersTableData,
    },
    {
      title: "Taşıması Tamamlanmamış Araç Listesi",
      tableData: busyTrailersTableData,
    },
  ];

  const renderTables = () =>
    freeBusyTables.map(({ title, tableData }) => (
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {title}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <DataTable
                    isSorted={false}
                    pagination={{ variant: "gradient", color: "info" }}
                    table={tableData}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderTables()}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}
export default ListFreeBusyTrailersStatus;
