import { countries } from "../../../constants/referenceData";

export const nrOfFieldsInContacts = 4; // This is to determine total count of fields while creating contacts
// Default values here only used for Dropdown , text input values are handled automatically by react hook form
export const inputFieldsMeta = ({ contactSize, alreadySelectedDropdownValues }) => [
  {
    dividerTitle: "Nakliyeci Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "name",
    label: "Ünvanı",
    isRequired: true,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "shortName",
    label: "Kısa İsim",
    isRequired: true,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "taxOffice",
    label: "Vergi Dairesi",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "taxId",
    label: "Vergi No",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    dividerTitle: "Adres Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "city",
    label: "Şehir",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "address",
    label: "Adres",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    selectedValue: alreadySelectedDropdownValues?.country,
    fieldName: "country",
    label: "Ülke Seçiniz",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: countries,
  },
  {
    dividerTitle: "İletişim Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "website",
    label: "Websitesi",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "phone",
    label: "Telefon",
    isRequired: false,
    isFullWidth: true,
    type: "tel",
    componentType: "input",
  },
  {
    fieldName: "email",
    label: "E-Mail",
    isRequired: false,
    isFullWidth: true,
    type: "email",
    componentType: "input",
  },
  {
    dividerTitle: "İletişim Kişileri",
    componentType: "dividerHeader",
  },
  {
    componentType: "dynamicInputFormMultipleRows",
    editModeContactSize: contactSize,
    inputs: {
      input: {
        fieldName: "forwarderContacts.name",
        label: "İsim",
        isRequired: false,
        isFullWidth: false,
        type: "text",
        componentType: "input",
      },
      input2: {
        fieldName: "forwarderContacts.surname",
        label: "Soyisim",
        isRequired: false,
        isFullWidth: false,
        type: "text",
        componentType: "input",
      },
      input3: {
        fieldName: "forwarderContacts.email",
        label: "E-Mail",
        isRequired: false,
        isFullWidth: false,
        type: "email",
        componentType: "input",
      },
      input4: {
        fieldName: "forwarderContacts.phone",
        label: "Telefon",
        isRequired: false,
        isFullWidth: false,
        type: "tel",
        componentType: "input",
      },
    },
  },
];
