import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetVehicles = async (params) => {
  const url = `${`${baseURL}/vehicles/getVehicles` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPICreateNewVehicle = (body) => {
  const url = `${baseURL}/vehicles/createNewVehicle`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetVehicleDetails = async (params) => {
  const url = `${`${baseURL}/vehicles/getVehicleDetails` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIUpdateVehicle = async (body) => {
  const url = `${baseURL}/vehicles/updateVehicle`;
  return callPUTMethodAPI(url, body);
};

export const callAPIGetExpiringVehicleDocs = async () => {
  const url = `${baseURL}/vehicles/getExpiringVehicleDocs`;
  return callGETMethodAPI(url);
};

export const callAPIGetFreeBusyTrailerStatus = async () => {
  const url = `${baseURL}/vehicles/getTrailerVehicleBusyFreeDetails`;
  return callGETMethodAPI(url);
};
