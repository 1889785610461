import { callPUTMethodAPI, objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI } from "./index";

export const callAPICreateNewDriverVisa = (body) => {
  const url = `${baseURL}/driverVisas/createNewDriverVisa`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetDriverVisas = async (params) => {
  const url = `${`${baseURL}/driverVisas/getDriverVisas` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIGetRecentCompanyDriverVisasPerCountry = async (params) => {
  const url = `${
    `${baseURL}/driverVisas/getRecentCompanyDriverVisasPerCountry` + "?"
  }${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIGetDriverVisaDetails = async (params) => {
  const url = `${`${baseURL}/driverVisas/getDriverVisaDetails` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPIUpdateDriverVisa = async (body) => {
  const url = `${baseURL}/driverVisas/updateDriverVisa`;
  return callPUTMethodAPI(url, body);
};
