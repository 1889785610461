import MDSnackbar from "components/MDSnackbar";


function SuccessMessage ({successMessage,show,close}) {
  return (
    <MDSnackbar
      color="success"
      icon="done_outline"
      title={successMessage || 'Islem başarıyla tamamlandi'}
      open={show}
      close={close}
    />
  )
}

export default SuccessMessage;
