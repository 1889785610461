
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { actionBaseColumns } from "pages/Common/baseContractDataTable";
import { commonActionsColumn } from "pages/Common/commonActionsColumnsInTable";
import RvHookupIcon from '@mui/icons-material/RvHookup';

export const generateVehicleTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%"
    },
    {
      Header: "Araç Türü",
      accessor: "type",
      align: "left",
      width: "15%"
    },
    {
      Header: "Plaka",
      accessor: "plateNumber",
      align: "left",
      width: "20%"
    },
    {
      Header: "Marka/Model",
      accessor: "brand",
      align: "left",
      width: "15%"
    },
    {
      Header: "Yorum",
      accessor: "comment",
      align: "center",
      width: "%25"
    },
    ...actionBaseColumns
  ];

  let rows = [];

  data.forEach(d => {
    const {plateNumber, type, brand, year, comment,id,entity} = d;

    const actionColumnParams = {
      id,
      entity:"vehicle",
      subEntity: entity,
      isEditMode: true,
      isCopyMode: true,
      details: false,
      download: false,
      remove:false,
      documents : true
    }

    rows.push({
      icon: (
          <LocalShippingIcon color="info" fontSize='medium'/>
      ),
      plateNumber,
      type,
      brand: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {brand}
          </MDTypography>
          <MDTypography variant="caption">{year}</MDTypography>
        </MDBox>
      ),
      comment: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {comment}
        </MDTypography>
      ),
      actions:  commonActionsColumn(actionColumnParams)
    });
  })

  return {
    rows,columns
  }
}
