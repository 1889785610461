// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// @mui material components
import DescriptionIcon from "@mui/icons-material/Description";

// Utils
import { commonActionsColumn } from "pages/Common/commonActionsColumnsInTable";
import { actionBaseColumns } from "pages/Common/baseContractDataTable";
import { returnShortDateString } from "../../../utils/dateUtils";
import { ENTITY_NAMES } from "constants/entityNames";

export const generateDriverVisasTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%",
    },
    {
      Header: "Ülke",
      accessor: "country",
      align: "center",
    },
    {
      Header: "Vize Tipi",
      accessor: "type",
      align: "left",
    },
    {
      Header: "Son Geçerlilik Tarihi",
      accessor: "validTo",
      align: "center",
    },
    ...actionBaseColumns,
  ];

  const rows = [];

  data.forEach((d) => {
    const { country, type, validTo, id } = d;
    const actionColumnParams = {
      id,
      entity: ENTITY_NAMES.driverVisa,
      isEditMode: true,
      isCopyMode: false,
      details: false,
      download: false,
      remove: false,
      documents: true,
      addVisa: false,
    };

    rows.push({
      icon: <DescriptionIcon color="info" fontSize="medium" />,
      country: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {country}
        </MDTypography>
      ),
      type: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {type}
        </MDTypography>
      ),
      validTo: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {validTo && returnShortDateString(validTo)}
        </MDTypography>
      ),
      actions: commonActionsColumn(actionColumnParams),
    });
  });

  return {
    rows,
    columns,
  };
};
