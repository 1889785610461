import { visaCountries } from "constants/referenceData";

export const inputFieldsMeta = ({ documentTypes ,alreadySelectedDropdownValues}) => [
  {
    fieldName: "type",
    label: "Vize Türü",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "validTo",
    label: "Son Geçerlilik Tarihi",
    isRequired: true,
    isFullWidth: true,
    type: "date",
    componentType: "input",
  },
  {
    fieldName: "country",
    label: "Ülke",
    isRequired: false,
    isFullWidth: true,
    selectedValue: alreadySelectedDropdownValues?.country,
    componentType: "dropdown",
    dropdownValues: visaCountries,
    dropdownWithId: false,
  },
  {
    fieldName: "documents",
    documentTypes,
    label: "Dosya Yükle",
    isRequired: false,
    isFullWidth: true,
    componentType: "uploadFile",
  },
];
