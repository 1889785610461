// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function FormHeader({title}) {
  return(
    <MDBox
      variant="gradient"
      bgColor="info"
      borderRadius="lg"
      coloredShadow="success"
      mx={12}
      mt={-2}
      p={3}
      py={1} mb={1}
      textAlign="center"
    >
        <MDTypography variant="h4" fontWeight="medium" color="white">
          {title}
        </MDTypography>
    </MDBox>
  )

}

export default FormHeader;