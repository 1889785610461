import React, { createContext, useContext, useReducer , useEffect} from "react";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { callAPILoginUser } from "../apiCalls/userApiCalls";
import { callAPIGetCompanyAlias } from "../apiCalls/companyApiCalls";
import { callAPICheckTokenValidity } from "../apiCalls/tokenApiCalls";
import { callAPIRevokeToken } from "../apiCalls/tokenApiCalls";
// The Soft UI Dashboard PRO Material main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

const userFromStorage = localStorage.getItem("username");
const token = localStorage.getItem("token");

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "REQUEST_LOGIN":
      return {
        ...authInitialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...authInitialState,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...authInitialState,
        user: "",
        token: "",
      };

    case "LOGIN_ERROR":
      return {
        ...authInitialState,
        errorMessage: action.error,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }

    case "COMPANY_ALIAS_SUCCESS":
      return {
        ...state,
        companyName: action.payload.companyName,
      };
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <MaterialUI.Provider value={[controller, dispatch]}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  loginUser,
  logout,
};

async function loginUser(dispatch, loginDetails) {
  const { email, password, companyName } = loginDetails;

  dispatch({ type: "REQUEST_LOGIN" });

  const response = await callAPILoginUser(null, {
    email,
    password,
    companyName,
  });

  if (response.status === 200) {
    const { data } = response.data;
    const { token, user } = data;

    if (data.user) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data,
      });
      await localStorage.setItem("token", token);
      await localStorage.setItem("companyId", user.companyId);
      await localStorage.setItem("username", user.username);

      // get company alias
      const companyAliasResponse = await callAPIGetCompanyAlias();
      if (companyAliasResponse.status === 200) {
        const { data } = companyAliasResponse.data;

        if (data.alias != null) {
          localStorage.setItem("companyName", data.alias);
          dispatch({
            type: "COMPANY_ALIAS_SUCCESS",
            payload: data,
          });
        }
      }

      return data;
    }

    dispatch({
      type: "LOGIN_ERROR",
      error: data.errors[0],
    });
  }
}

async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  await callAPIRevokeToken();
  localStorage.clear();
}

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}

const authInitialState = {
  user: "" || userFromStorage,
  token: "" || token,
  loading: true,
  errorMessage: null,
};

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(reducer, authInitialState);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await callAPICheckTokenValidity(token);
          if (response.status !== 200) {
            dispatch({ type: "LOGOUT" });
            localStorage.clear();
          }
        } catch (error) {
          dispatch({ type: "LOGOUT" });
          localStorage.clear();
        }
      } else {
        dispatch({ type: "LOGOUT" });
        localStorage.clear();
      }
    };
    checkToken();
  }, [dispatch, navigate]);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
