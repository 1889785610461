import React from "react";
import { Grid, Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";

function TableCard({ isFull, title, tableData, isLoading }) {
  return (
    <Grid item xs={isFull ? 12 : 3} py={isFull ? 6 : 3} md={isFull ? 6 : 3} lg={isFull ? 12 : 6}>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            {title}
          </MDTypography>
        </MDBox>
        <MDBox py={3}>
          {isLoading ? (
            <LoaderSpinner />
          ) : (
            <DataTable
              pagination={{ color: "info" }}
              isSorted={false}
              table={tableData}
              entriesPerPage={{ defaultValue: 5, entries: [5, 10] }}
              canSearch={false}
              showTotalEntries={false}
              noEndBorder
            />
          )}
        </MDBox>
      </Card>
    </Grid>
  );
}

export default TableCard;