import axios from "axios";
import React from "react";
import { get } from "lodash";
import environmentsConfig from "../environments";

// Creates url param string
export const objectToQueryString = (obj) => {
  if (obj) {
    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join("&");
  }
};

export const generateAPIHeaders = (isDownloadFile=false) => {
  const token = localStorage.getItem("token");
  let params = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  if(isDownloadFile) {
    params = { ...params, responseType: "blob" };
  }
  return params;
};

export const callGETMethodAPI = async (url,isDownloadFile) => await axios.get(url, generateAPIHeaders(isDownloadFile));
export const callPOSTMethodAPI = async (url, body) =>
  await axios.post(url, body, generateAPIHeaders());

export const callPUTMethodAPI = async (url, body) =>
  await axios.put(url, body, generateAPIHeaders());

export const callDELETEMethodAPI = async (url) =>
  await axios.delete(url, generateAPIHeaders());


const env = process.env.NODE_ENV || "development";
const config = environmentsConfig[env];

export const baseURL = process.env.REACT_APP_API_ENDPOINT || config.apiUrl;

// export let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImhiYXlyYW1rYWFuaW50ZXJuYXRpb25hbCIsImNvbXBhbnlJZCI6MSwicm9sZSI6Ik1BTkFHRVIiLCJ1c2VySWQiOjQsImlhdCI6MTY0NzIwNzU3MSwiZXhwIjoxNjc4NzQzNTcxfQ.Ob21q_Hzj3EoQMrdMf_wBXAvSedkUyLie1gbGCV3Nso';
/// localStorage.getItem('token');

export let token = localStorage.getItem("token");

