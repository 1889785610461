//React
import react from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

function LoaderSpinner() {
return(
  <Grid container style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '20vh'}}  >
    <CircularProgress color="info"/>
  </Grid>
)

}

export default LoaderSpinner;