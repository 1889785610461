// Material Dashboard 2 React layouts
import Dashboard from "pages/archive/dashboard";
import Tables from "pages/archive/tables";
import Billing from "pages/archive/billing";
import Notifications from "pages/archive/notifications";
import Profile from "pages/archive/profile";
import SignIn from "pages/archive/authentication/sign-in";
import SignUp from "pages/archive/authentication/sign-up";
import ListForwarders from "pages/Forwarders/ListForwarders";
import ForwarderDetails from "pages/Forwarders/ForwarderDetails";
import ListCustomers from "pages/Customers/ListCustomers";
import CustomerDetails from "pages/Customers/CustomerDetails";
import ListDrivers from "pages/Drivers/ListDrivers";
import CreateEditDriver from "pages/Drivers/CreateEditDriver";
import Home from "pages/Home";
import CreateEditContract from "pages/Contracts/CreateEditContract/index";
import ContractDetails from "pages/Contracts/ContractDetails";
import ListContracts from "pages/Contracts/ListContracts";
import Login from "pages/Login";
import CreateEditOperator from "pages/Operators/CreateEditOperator";
import ListOperators from "pages/Operators/ListOperators";
import CreateEditForwarderStatusUpdates from "pages/Forwarders/CreateEditForwarderStatusUpdates";
import CreateEditForwarder from "pages/Forwarders/CreateEditForwarder";
import CreateEditCustomer from "pages/Customers/CreateEditCustomer";
import ListForwarderContractsInDue from "pages/Contracts/ListDueContracts/ListForwarderDueContracts";
import UserDetails from "pages/User/UserDetails";
import ListDocuments from "pages/Documents/ListDocuments";
import CreateEditVehicle from "pages/Vehicles/CreateEditVehicle";

// @mui icons
import Icon from "@mui/material/Icon";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import BusinessCenterTwoToneIcon from "@mui/icons-material/BusinessCenterTwoTone";
import AirlineSeatLegroomExtraTwoToneIcon from "@mui/icons-material/AirlineSeatLegroomExtraTwoTone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentTwoToneIcon from "@mui/icons-material/AssignmentTwoTone";
import GroupIcon from "@mui/icons-material/Group";
import ListVehicles from "pages/Vehicles/ListVehicles";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import CreateEditDriverVisa from "pages/DriverVisas/CreateEditDriverVisa";
import ListDriverVisas from "pages/DriverVisas/ListDriverVisas";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ListOperators2 from "./pages/Operators/ListOperatorsNewTable";
import ListFreeBusyTrailersStatus from "./pages/Vehicles/ListFreeBusyTrailersStatus";

// TODO: Make common paths reusable - create edit list etc.

const routes = [
  {
    type: "collapse",
    name: "Anasayfa",
    key: "home",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/home",
    component: <Home />,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    hideOnMenu: true,
    isPublic: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    hideOnMenu: true,
    isPublic: false,
  },
  {
    type: "collapse",
    name: "Taşımalar",
    key: "list-contracts",
    icon: <AssignmentTwoToneIcon />,
    route: "/list-contracts",
    component: <ListContracts />,
    isPublic: false,
    hideOnMenu: false,
  },
  // TODO: change entity name to sub entity, make them reusable, or eventually decouple vehicles  
  {
    type: "collapse",
    name: "Çekiciler",
    key: "list-trailers",
    icon: <RvHookupIcon />,
    route: "/list-trailers",
    component: <ListVehicles entity="trailer" />,
    isPublic: false,
  },
  {
    type: "collapse",
    name: "Çekici Durumu",
    key: "list-free-busy-trailer-status",
    route: "/list-free-busy-trailer-status",
    component: <ListFreeBusyTrailersStatus />,
    icon: <RvHookupIcon />,
    isPublic: false,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Dorseler",
    key: "list-tow-trucks",
    icon: <AgricultureIcon />,
    route: "/list-tow-trucks",
    component: <ListVehicles entity="towTruck" />,
    isPublic: false,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Evraklar",
    key: "list-documents",
    icon: <LocalShippingTwoToneIcon />,
    route: "/list-documents",
    component: <ListDocuments />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Nakliyeciler",
    key: "list-forwarders",
    icon: <LocalShippingTwoToneIcon />,
    route: "/list-forwarders",
    component: <ListForwarders />,
    isPublic: false,
  },
  {
    type: "collapse",
    name: "Nakliyeci Detayı",
    key: "forwarder-details",
    icon: <LocalShippingTwoToneIcon />,
    route: "/forwarder-details",
    component: <ForwarderDetails />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Müşteriler",
    key: "list-customers",
    icon: <BusinessCenterTwoToneIcon />,
    route: "/list-customers",
    component: <ListCustomers />,
    isPublic: false,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Müşteri Detayı",
    key: "customer-details",
    icon: <BusinessCenterTwoToneIcon />,
    route: "/customer-details",
    component: <CustomerDetails />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Şöforler",
    key: "list-drivers",
    icon: <AirlineSeatLegroomExtraTwoToneIcon />,
    route: "/list-drivers",
    component: <ListDrivers />,
    isPublic: false,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Create Edit Driver",
    key: "create-edit-driver",
    icon: <AirlineSeatLegroomExtraTwoToneIcon />,
    route: "/create-edit-driver",
    component: <CreateEditDriver />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Create Edit Contract",
    key: "create-edit-contract",
    icon: <AssignmentTwoToneIcon />,
    route: "/create-edit-contract",
    component: <CreateEditContract />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Contract Details",
    key: "contract-details",
    route: "/contract-details",
    component: <ContractDetails />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/authentication/login",
    component: <Login />,
    isPublic: true,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Operator Oluştur/Düzenle",
    key: "create-edit-operator",
    route: "/create-edit-operator",
    component: <CreateEditOperator />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Operatorler",
    key: "list-operators",
    route: "/list-operators",
    component: <ListOperators />,
    isPublic: false,
    icon: <GroupIcon />,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Nakliyeci Durum Bilgisi Güncelleme",
    key: "forwarder-status-create-edit",
    route: "/create-edit-forwarder-status-updates",
    component: <CreateEditForwarderStatusUpdates />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Nakliyeci Oluştur/Düzenle",
    key: "forwarder-create-edit",
    route: "/create-edit-forwarder",
    component: <CreateEditForwarder />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Müşteri Oluştur/Düzenle",
    key: "customer-create-edit",
    route: "/create-edit-customer",
    component: <CreateEditCustomer />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Vadesi Gelen Nakliye Faturlarini Listele",
    key: "forwarder-contracts-in-due",
    route: "/list-forwarder-contracts-in-due",
    component: <ListForwarderContractsInDue />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Profil",
    key: "user-details",
    route: "/user-details",
    component: <UserDetails />,
    icon: <AccountCircleIcon />,
    isPublic: false,
    hideOnMenu: false,
  },
  {
    type: "collapse",
    name: "Araç Oluştur/Düzenle",
    key: "create-edit-vehicle",
    route: "/create-edit-vehicle",
    component: <CreateEditVehicle />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Vize Oluştur/Düzenle",
    key: "create-edit-visa",
    route: "/create-edit-visa",
    component: <CreateEditDriverVisa />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Vizeler",
    key: "list-driver-visas",
    route: "/list-driver-visas",
    component: <ListDriverVisas />,
    isPublic: false,
    hideOnMenu: true,
  },
  {
    type: "collapse",
    name: "Vizeler",
    key: "list-operators2",
    route: "/list-operators2",
    component: <ListOperators2 />,
    isPublic: false,
    hideOnMenu: true,
  },
];

export default routes;
