import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetAllEmployeesForCompany = async (params) => {
  const url = `${`${baseURL}/employees/getAllEmployeesForCompany` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPIGetAllEmployeesDropdownView = async (params) => {
  const url = `${`${baseURL}/employees/getAllEmployeesDropdownView` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPICreateNewEmployee = async (body) => {
  const url = `${baseURL}/employees/createNewEmployee`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetEmployeeDetails = async (params) => {
  const url = `${`${baseURL}/employees/getEmployeeDetails` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIUpdateEmployee = async (params, body) => {
  const url = `${`${baseURL}/employees/updateEmployee` + "?"}${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};
