import React from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";

function ChartCard({ color, title, description, data }) {
  return (
    <Grid item xs={6} md={6} lg={12}>
      <MDBox mb={3}>
        <ReportsLineChart
          color={color}
          title={title}
          description={description}
          chart={data}
        />
      </MDBox>
    </Grid>
  );
}

export default ChartCard;