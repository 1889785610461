import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export function FormInputField(props) {

  const {register,validationErrors,mode,formControllerKey,dynamicFieldName, metaData: {label,isRequired,isFullWidth,type,topMargin,maxLength}} = props;

  let {metaData:{fieldName}} =props;
  //dynamic field name is used for forms like contact form where you add multiple rows
  //so that you can have few contact rows
  fieldName = dynamicFieldName ? `${fieldName}${(formControllerKey+1)}` : fieldName;

  return (
    <>
      <MDBox mt={topMargin || 2}>
        {mode==="edit" || type === 'date'?
          <MDInput fullWidth={isFullWidth}
                   required={isRequired} error={validationErrors[fieldName]}
                   {...register(fieldName, {required: isRequired,maxLength: maxLength ||1000})}
                   InputLabelProps={{ shrink: true }}
                   type={type} label={label}  variant="standard"/>
          :
          <MDInput fullWidth={isFullWidth}
                   required={isRequired} error={validationErrors[fieldName]}
                   {...register(fieldName, {required: isRequired,maxLength: maxLength||1000})}
                   type={type} label={label}  variant="standard"/>
        }
      </MDBox>
      {validationErrors[fieldName]?.type==="required" &&
      <MDTypography variant="caption" color="error">Bu alan zorunludur.</MDTypography>}
      {validationErrors[fieldName]?.type ==="maxLength" &&
      <MDTypography variant="caption" color="error">En fazla {maxLength || 1000} karakter kullanabilirsiniz</MDTypography>}
    </>
  )
}


export function FormMultipleInputsInLine(props) {
  const { metaData: {label}} = props;

  return (
    <MDBox sx={{
      display: 'flex',
      justifyContent: 'space-between'
    }} mb={2} mt={2}>

      <MDTypography mt={2} variant="body2"  textTransform="capitalize">
        {label}
      </MDTypography>
      {generateInputsForSameLine(props)}
    </MDBox>
  )
}


const generateInputsForSameLine = (props) => {
  const {register,validationErrors,mode, metaData: {inputs}} = props;

  let arrOfComponents = [];
  for(const [key,values] of Object.entries(inputs)) {
    const {width,isRequired,fieldName,type,label,maxLength} = values;
    arrOfComponents.push(
      <>
        {mode === 'edit' || type === 'date'
          ?
          <MDInput sx={{width}} required={isRequired}
                   {...register(fieldName, {
                     required: isRequired,
                     maxLength: maxLength || 1000
                   })}
                   InputLabelProps={{shrink: true}}
                   error={validationErrors[fieldName]}
                   type={type} label={label}
                   variant="standard"/>

          :

          <MDInput sx={{width}} required={isRequired}
                   {...register(fieldName, {
                     required: isRequired,
                     maxLength: maxLength || 1000
                   })}
                   error={validationErrors[fieldName]}
                   type={type} label={label}
                   variant="standard"/>
        }
        {validationErrors[fieldName]?.type === "required" &&
        <MDTypography variant="caption" color="error">Bu alan zorunludur.</MDTypography>}
        {validationErrors[fieldName]?.type === "maxLength" &&
        <MDTypography variant="caption" color="error">En fazla {maxLength || 1000} karakter
          kullanabilirsiniz</MDTypography>}
      </>
    )
  }
  return arrOfComponents;
}