// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { actionBaseColumns } from "pages/Common/baseContractDataTable";
import { commonActionsColumn } from "pages/Common/commonActionsColumnsInTable";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import { returnShortDateString } from "../../../utils/dateUtils";
import { ENTITY_NAMES } from "constants/entityNames";

export const generateFreeBusyTrailerStatusColumnsRowsData = async (data, isFree) => {
  let columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%",
    },
    {
      Header: "Plaka",
      accessor: "plateNumber",
      align: "center",
    },
    {
      Header: `${isFree ? "En son yük boşaltma tarihi" : "Gelecek yük boşaltma Tarihi"}`,
      accessor: "arrDate",
      align: "center",
    },
  ];

  if (isFree) {
    columns.push({
      Header: "Aracın Boşta Olduğu Gün",
      accessor: "freeSince",
      align: "center",
    });
  } else {
    columns.push({
      Header: "Araç Kaç Gün İçinde Boşa Düşecek",
      accessor: "busyUntil",
      align: "center",
    });
  }

  columns = [...columns, ...actionBaseColumns];

  const rows = [];

  data.forEach((d, index) => {
    const { plateNumber, freeSince, busyUntil, id , lastArrivingDate,nextArrivingDate } = d;
    const lastArrDate = lastArrivingDate ? returnShortDateString(lastArrivingDate) : "-";
    const nextArrDate = nextArrivingDate ? returnShortDateString(nextArrivingDate) : "-";

    const actionColumnParams = {
      id,
      entity: ENTITY_NAMES.vehicle,
      isEditMode: false,
      isCopyMode: false,
      details: false,
      download: false,
      remove: false,
      documents: false,
    };

    rows.push({
      icon: <LocalShippingIcon color="info" fontSize="medium" />,
      plateNumber,
      arrDate: isFree ? lastArrDate : nextArrDate
    });

    if (isFree) {
      rows[index] = {
        ...rows[index],
        freeSince,
      };
    } else {
      rows[index] = {
        ...rows[index],
        busyUntil,
      };
    }

    rows[index] = {
      ...rows[index],
      actions: commonActionsColumn(actionColumnParams),
    };
  });

  return {
    rows,
    columns,
  };
};
