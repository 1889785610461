import { countries } from "../../../constants/referenceData";

export const dropdownFields = ["country"];

export const inputFieldsMeta = ({ documentTypes, alreadySelectedDropdownValues }) => [
  {
    dividerTitle: "Şoför Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "name",
    errorMessage: `İsim kismi zorunludur`,
    label: "İsim",
    isRequired: true,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "surname",
    errorMessage: `Soyisim kismi zorunludur`,
    label: "Soyisim",
    isRequired: true,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "birthday",
    label: "Doğum Tarihi",
    isRequired: false,
    defaultValue: null,
    isFullWidth: true,
    type: "date",
    componentType: "input",
  },
  {
    dividerTitle: "Adres Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "country",
    label: "Ülke",
    isRequired: false,
    isFullWidth: true,
    selectedValue: alreadySelectedDropdownValues?.country,
    componentType: "dropdown",
    dropdownValues: countries,
    labelController: "name",
    dropdownWithIdValue: false,
  },

  {
    fieldName: "city",
    label: "Şehir",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "address",
    label: "Adres",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "phone",
    label: "Telefon",
    isRequired: false,
    isFullWidth: true,
    componentType: "input",
  },
  {
    fieldName: "documents",
    documentTypes,
    label: "Dosya Yükle",
    isRequired: false,
    isFullWidth: true,
    componentType: "uploadFile",
  },
];
