import { Fragment, React, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MDBox from 'components/MDBox';
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {FormInputField} from '../FormInputField';
import {FormAutoCompleteDropdownField} from '../FormAutoCompleteDropdownField';

export function ContactForm(props) {

  const [formCountController, setFormCountController] = useState(1);
  const {mode,setValue,metaData:{editModeContactSize}} = props;

  const removeContactFromRow = async () => {

    for(const [key,metaData] of Object.entries(props.metaData.inputs)) {
      const {fieldName} = metaData;
       setValue(`${fieldName}${formCountController}`);

    }
    setFormCountController(formCountController-1);
  }

  useEffect( async () => {

    if(mode==='edit'){
      setFormCountController(editModeContactSize)
    }

  }, [])

  return(
    <Fragment>
      {[...Array(formCountController)].map((_, key) =>
        <MDBox sx={{
          display: 'flex',
          justifyContent: 'space-evenly'
        }} key={key} mt={2} mb={2}>
          <AccountCircle color="info" sx={{
            color: 'info',
            mr: 0.2,
            my: 3
          }}/>
          {generateInputs(props,key)}
          {formCountController === key + 1 && <AddCircleOutlineTwoToneIcon onClick={() => {
            formCountController !== 5 && setFormCountController(formCountController + 1);
          }} color={`${formCountController === 5 ? 'disabled' : 'info'}`} style={{
            marginLeft: '5px',
            marginTop: '25px'
          }}/>}
          {formCountController === key + 1 &&
          <RemoveCircleOutlineTwoToneIcon onClick={() => {
            formCountController !== 1 && removeContactFromRow();
             }} color={`${formCountController === 1 ? 'disabled' : 'info'}`} style={{
            marginLeft: '5px',
            marginTop: '25px'
          }}/>}
        </MDBox>
      )}
    </Fragment>
  )
}


const generateInputs = (props,formControllerKey) => {
  const {register,reset,control,setValue,getValues,validationErrors,mode, metaData: {inputs}} = props;

  let arrOfComponents = [];
  for(const [key,metaData] of Object.entries(inputs)) {
    const {componentType} = metaData;

    let component;

    if(componentType === 'input') {
      component =
        <FormInputField
          formControllerKey={formControllerKey}
          mode={mode}
          dynamicFieldName={true}
          setValue={setValue}
          control={control}
          register={register}
          validationErrors={validationErrors}
          metaData={metaData}
        />
    }
    else if(componentType === 'dropdown') {
      component =
        <FormAutoCompleteDropdownField
          reset={reset}
          mode={mode}
          dynamicFieldName={true}
          getValues={getValues}
          setValue={setValue}
          control={control}
          metaData={metaData}
          validationErrors={validationErrors}
          register={register}
        />
    }
    arrOfComponents.push(component);
  }
  return arrOfComponents;
}