import { React, useEffect, useState, useRef } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link } from "react-router-dom";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";
import { createDocument } from "pages/Common/documentActions";
import {
  currencies,
  defaultCurrency,
  freightTypes,
  invoiceTypes,
  vehicleEntities,
  countries,
} from "../../../constants/referenceData";
import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";

import { callAPIGetAllForwardersDropdownView } from "../../../apiCalls/forwarderApiCalls";
import { callAPIGetAllCustomersDropdownView } from "../../../apiCalls/customerApiCalls";
import { callAPIGetAllEmployeesDropdownView } from "../../../apiCalls/employeeApiCalls";
import {
  callAPICreateNewContract,
  callAPIGetContractDetailsForUpdate,
  callAPIUpdateContract,
} from "../../../apiCalls/contractApiCalls";
import { inputFieldsMeta, dropdownFields } from "./inputFieldsMeta";
import Form from "../../../components/CustomComponents/CreateEditForms/Form";
import { callAPIGetVehicles } from "../../../apiCalls/vehicleApiCalls";
import { callAPIGetDocumentTypes } from "../../../apiCalls/documentTypeApiCalls";
import { callAPIGetAllDriversDropdownView } from "../../../apiCalls/driverApiCalls";
import { ENTITY_NAMES } from "constants/entityNames";

function CreateEditContract() {
  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const [ownTransportContractForTitle, setOwnTransportContractForTitle] = useState();
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [newContractId, setNewContractId] = useState();
  const [inputFieldsMetaData, setInputFieldsMetaData] = useState(null);

  let existingContractValues = {};
  let alreadySelectedDropdownValues = {};

  let forwarders = [];
  let customers = [];
  let employees = [];
  let trucks = [];
  let trailers = [];
  let drivers = [];
  let towTrucks = [];
  let vehicles = [];
  let documentTypes = [];

  useEffect(async () => {
    forwarders = await getForwarders();
    customers = await getCustomers();
    employees = await getEmployees();
    vehicles = await getVehicles();

    trucks = vehicles.filter((v) => v.entity === vehicleEntities[0]);
    towTrucks = vehicles.filter((v) => v.entity === vehicleEntities[1]);
    trailers = vehicles.filter((v) => v.entity === vehicleEntities[2]);

    drivers = await getDrivers();
    documentTypes = await getDocumentTypes();

    if (mode === "edit" || mode === "copy") {
      existingContractValues = await getContractDetails();
      await getDropdownIndexesForExistingValues();

      setOwnTransportContractForTitle(existingContractValues.forwardOrOwnTransport === "OWN");
      const ownTransportContract = existingContractValues.forwardOrOwnTransport === "OWN";
      setInputFieldsMetaData(
        inputFieldsMeta({
          documentTypes,
          towTrucks,
          trailers,
          drivers,
          trucks,
          forwarders,
          customers,
          employees,
          alreadySelectedDropdownValues,
          ownTransportContract,
        })
      );
      setExistingValuesForForm();
      setIsLoading(false);
    } else {
      const ownTransportContract = urlParams.searchParams.get("ownTransport") === "true";
      setOwnTransportContractForTitle(urlParams.searchParams.get("ownTransport") === "true");
      setInputFieldsMetaData(
        inputFieldsMeta({
          documentTypes,
          towTrucks,
          trailers,
          drivers,
          trucks,
          forwarders,
          customers,
          employees,
          ownTransportContract,
        })
      );
      setDefaultValues();
      setIsLoading(false);
    }
  }, []);

  const getDropdownIndexesForExistingValues = async () => {
    let dataSet;

    for (const [key, value] of Object.entries(existingContractValues)) {
      if (value !== null || value !== "") {
        const isItDropdown = dropdownFields.includes(key);

        if (isItDropdown) {
          let dropdownWithId = false;
          if (key === "forwarderId") {
            dataSet = forwarders;
            dropdownWithId = true;
          } else if (key === "contractType") dataSet = invoiceTypes;
          else if (key === "vehicleType") dataSet = vehicleEntities;
          else if (key === "freightType") dataSet = freightTypes;
          else if (key === "employeeId") {
            dataSet = employees;
            dropdownWithId = true;
          } else if (key === "vehicleTruckId") {
            dataSet = trucks;
            dropdownWithId = true;
          } else if (key === "vehicleTowTruckId") {
            dataSet = towTrucks;
            dropdownWithId = true;
          } else if (key === "vehicleTrailerId") {
            dataSet = trailers;
            dropdownWithId = true;
          } else if (key === "customerId") {
            dataSet = customers;
            dropdownWithId = true;
          } else if (key === "driverId") {
            dataSet = drivers;
            dropdownWithId = true;
          } else if (key === "loadingCountry" || "offLoadingCountry") dataSet = countries;
          else if (key === "forwarderInvoiceCurrency" || "customerInvoiceCurrency")
            dataSet = currencies;

          const index = dataSet
            .map((values) => {
              if (dropdownWithId) return values.id;
              return values;
            })
            .indexOf(value);
          alreadySelectedDropdownValues = {
            ...alreadySelectedDropdownValues,
            [key]: dataSet[index],
          };
        }
      }
    }
  };

  const setExistingValuesForForm = async () => {
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(existingContractValues)) {
      if (key === "contractDate" || key === "arrivingDate" || key === "departingDate") {
        value = value ? value.split("T")[0] : null;
      }

      // If mode is copy , do not set arr date dept date id and contract no
      if (
        !(
          mode === "copy" &&
          (key === "arrivingDate" ||
            key === "departingDate" ||
            key === "id" ||
            key === "contractNo" ||
            key === "contractDate")
        )
      ) {
        if (value !== null || value !== "" || key === "createdAt" || key === "updatedAt") {
          formRef.current.setValue(key, value);
        }
      }
    }
  };

  const setDefaultValues = async () => {
    formRef.current.setValue("customerInvoiceCurrency", defaultCurrency);
    formRef.current.setValue("forwarderInvoiceCurrency", defaultCurrency);
  };

  const getDocumentTypes = async () => {
    try {
      const result = await callAPIGetDocumentTypes({ entity: ENTITY_NAMES.contract });
      const parsedResult = await parseAPICall(result);
      return parsedResult;
    } catch (err) {
      return showErrorMsg("Dokuman", err);
    }
  };

  const getVehicles = async () => {
    try {
      const result = await callAPIGetVehicles();
      const parsedResult = await parseAPICall(result);
      return parsedResult;
    } catch (err) {
      return showErrorMsg("Araç", err);
    }
  };

  const getForwarders = async () => {
    try {
      const result = await callAPIGetAllForwardersDropdownView();
      const parsedResult = await parseAPICall(result);
      return parsedResult;
    } catch (err) {
      return showErrorMsg("Nakliyeci", err);
    }
  };

  const getCustomers = async () => {
    try {
      const result = await callAPIGetAllCustomersDropdownView();
      const parsedResult = await parseAPICall(result);
      return parsedResult;
    } catch (err) {
      return showErrorMsg("Müşteri", err);
    }
  };

  const getEmployees = async () => {
    try {
      const result = await callAPIGetAllEmployeesDropdownView();
      const parsedResult = await parseAPICall(result);
      return parsedResult;
    } catch (err) {
      return showErrorMsg("Calisan", err);
    }
  };

  const getDrivers = async () => {
    try {
      const result = await callAPIGetAllDriversDropdownView();
      const parsedResult = await parseAPICall(result);
      return parsedResult;
    } catch (err) {
      return showErrorMsg("Şoför", err);
    }
  };

  const showErrorMsg = async (entity, errorDetails) => {
    setIsError(true);
    setErrorMessage(`${entity} listesi yüklenirken bir hata oluştu.Hata detayi:  ${errorDetails}`);
  };

  const parseAPICall = async (result) => {
    if (result.status === 200) return result.data.data;
    throw `${result.data.message}`;
  };

  const getContractDetails = async () => {
    const id = urlParams.searchParams.get("contractId");
    try {
      const contractDetailsResponse = await callAPIGetContractDetailsForUpdate({
        contractId: id,
      });
      if (contractDetailsResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return contractDetailsResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Taşıma yüklenirken bir hata oluştu. Hata detayı: ",
        contractDetailsResponse.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Taşıma yüklenirken bir hata oluştu.");
    }
  };

  const createNewContract = async (data) => {
    const { documents, ...newContractData } = data;

    const newContract = {
      ...newContractData,
      forwardOrOwnTransport: ownTransportContractForTitle ? "OWN" : "FORWARDER",
    };

    try {
      const resultCreateNewContract = await callAPICreateNewContract(newContract);

      if (resultCreateNewContract.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);

        setNewContractId(resultCreateNewContract.data.data.id);
        setSuccessMessage(
          `${resultCreateNewContract.data.data.contractNo} nolu taşıma başarıyla oluşturuldu.Detaya gitmek için tıklayıniz`
        );

        if (documents?.length > 0) {
          const documentDetails = {
            documents,
            entity: ENTITY_NAMES.contract,
            entityId: resultCreateNewContract.data.data.id,
          };

          await createDocument(documentDetails);
          formRef.current.resetForm();
        }
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Taşıma oluşturulurken bir hata oluştu. Hata detayı: ",
          resultCreateNewContract.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Taşıma oluşturulurken bir hata oluştu.");
    }
  };

  const updateContract = async (data) => {
    try {
      const resultUpdatedContract = await callAPIUpdateContract(null, data);
      if (resultUpdatedContract.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setNewContractId(data.id);
        setSuccessMessage(
          `${data.contractNo} nolu taşıma başarıyla güncellendi.Detaya gitmek için tıklayıniz`
        );
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Taşıma güncellenirken bir hata oluştu. Hata detayı: ",
          resultUpdatedContract.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Taşıma güncellenirken bir hata oluştu.");
    }
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    const contractData = {
      ...data,
    };

    if (mode === "edit") {
      updateContract(contractData);
    } else {
      createNewContract(contractData);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {inputFieldsMetaData ? (
        <Form
          ref={formRef}
          mode={mode}
          handleSave={handleSave}
          isLoading={isLoading}
          title={
            ownTransportContractForTitle === true
              ? `Özmal Taşıma Kaydı ${mode === "edit" ? "Güncelle" : "Oluştur"}`
              : `Nakliyeci Taşıma Kaydı ${mode === "edit" ? "Güncelle" : "Oluştur"} `
          }
          inputFields={inputFieldsMetaData}
        />
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Link to={`/contract-details?${newContractId}`}>
        <SuccessMessage
          close={() => setIsSuccess(false)}
          successMessage={successMessage}
          show={isSuccess}
        />
      </Link>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateEditContract;
