// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { commonActionsColumn } from "pages/Common/commonActionsColumnsInTable";
import { ENTITY_NAMES } from "constants/entityNames";

export const generateForwardersTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%",
    },
    {
      Header: "Nakliyeci",
      accessor: "shortName",
      align: "left",
    },
    {
      Header: "Ülke/Şehir",
      accessor: "country",
      align: "left",
    },
    {
      Header: "Adres",
      accessor: "address",
      align: "left",
    },
    {
      Header: "Telefon",
      accessor: "phone",
      align: "left",
    },
    {
      Header: "E-Mail",
      accessor: "email",
      align: "center",
    },
    {
      Header: "Website",
      accessor: "website",
      align: "center",
    },
    {
      Header: "",
      accessor: "actions",
      align: "center",
    },
  ];

  const rows = [];

  data.forEach((d) => {
    const { shortName, address, country, city, email, phone, website, id } = d;

    const actionColumnParams = {
      id,
      entity: ENTITY_NAMES.forwarder,
      isEditMode: true,
      isCopyMode: true,
      details: true,
      download: false,
      remove: false,
      documents: false,
    };

    rows.push({
      icon: <LocalShippingIcon color="info" fontSize="medium" />,
      shortName,
      address: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {address}
        </MDTypography>
      ),
      country: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {country}
          </MDTypography>
          <MDTypography variant="caption">{city}</MDTypography>
        </MDBox>
      ),
      email: (
        <MDTypography
          component="a"
          href={`emailto:${email}`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {email}
        </MDTypography>
      ),
      phone: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {phone}
        </MDTypography>
      ),
      website: (
        <MDTypography
          component="a"
          href={website}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {website}
        </MDTypography>
      ),
      actions: commonActionsColumn(actionColumnParams),
    });
  });

  return {
    rows,
    columns,
  };
};
