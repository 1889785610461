import { baseURL, callGETMethodAPI, callPOSTMethodAPI } from "./index";

export const callAPILoginUser = async (params, body) => {
  const url = `${baseURL}/users/userLogin`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetUserDetails = async () => {
  const url = `${baseURL}/users/getUserDetails`;
  return callGETMethodAPI(url);
};
