import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetForwarderContacts = async (params) => {
  const url = `${`${baseURL}/forwarderContacts/getForwarderContacts` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPICreateForwarderContacts = async (body) => {
  const url = `${baseURL}/forwarderContacts/createForwarderContacts`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIUpdateForwarderContacts = async (params, body) => {
  const url = `${`${baseURL}/forwarderContacts/updateForwarderContacts` + "?"}${objectToQueryString(
    params
  )}`;
  return callPUTMethodAPI(url, body);
};
