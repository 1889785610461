export const baseColumns = [
  {
    Header: "Taşıma No",
    accessor: "contractNo",
    align: "left",
  },
  {
    Header: "Müşteri",
    accessor: "customer",
    align: "center",
  },
  {
    Header: "Müşteri Fiyati",
    accessor: "customerPrice",
    align: "center",
  },
  {
    Header: "Musteri Odeme Durumu",
    accessor: "customerPaymentStatus",
    align: "center",
  },
  {
    Header: "Yüklenme Tarihi",
    accessor: "departingDate",
    align: "center",
  },
  {
    Header: "Operator",
    accessor: "operator",
    align: "center",
  },
];

export const actionBaseColumns = [
  {
    Header: "",
    accessor: "actions",
    align: "center",
  },
];
