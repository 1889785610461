import { Fragment, React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import MDInput from "components/MDInput";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link, Navigate } from "react-router-dom";
import DetailsPageInfoDetailsCard from "components/CustomComponents/DetailsPageCards/DetailsPageInfoDetailsCard";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import { callAPIGetUserDetails } from "../../../apiCalls/userApiCalls";
import DetailsPageHeaders from "../../../components/CustomComponents/DetailsPageHeaders/DetailsPageHeaders";
import { logout, useAuthDispatch } from "../../../context";

function UserDetails() {
  const dispatchAuth = useAuthDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userDetails, setUserDetails] = useState({
    id: 0,
  });

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleLogout = async () => {
    await logout(dispatchAuth);
    return <Navigate to="/home" />;
  };

  const getUserDetails = async () => {
    try {
      const result = await callAPIGetUserDetails();
      if (result.status === 200) {
        setUserDetails(result.data.data);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Nakliyeci görüşmeleri yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci görüşmeleri yüklenirken bir hata oluştu.");
    }
  };

  const { id, name, surname, role, phone, email, address, titleInCompany } = userDetails;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DetailsPageHeaders
        initials={name && `${name.charAt(0).toUpperCase()}${surname?.charAt(0).toUpperCase()}`}
        name={!isLoading && `${name} ${surname}`}
        type={!isLoading && titleInCompany}
      >
        <MDBox mt={5} mb={3}>
          <Grid item xs={12} md={6} xl={4}>
            <DetailsPageInfoDetailsCard
              isLoading={isLoading}
              title="Kullanıcı Bilgileri"
              info={{
                name,
                surname,
                Telefon: phone,
                email,
                adres: address,
                // role: role
              }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
          </Grid>
        </MDBox>
        <MDTypography
          onClick={() => handleLogout()}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Link
            to={{
              pathname: "/home",
            }}
          >
            Çıkış Yap
          </Link>
        </MDTypography>
      </DetailsPageHeaders>

      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default UserDetails;
