// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// React
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Custom Library
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import LoaderSpinner from "../../../components/CustomComponents/Loader/LoaderSpinner";
import { callAPIGetAllEmployeesForCompany } from "../../../apiCalls/employeeApiCalls";
import { generateOperatorsTableColumnsRowsData } from "./operatorsDataTable";

function ListOperators() {
  const [tableData, setTableData] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    await getAllOperatorsOfCompany();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
  const getAllOperatorsOfCompany = async () => {
    let tableRowsColumns;
    try {
      const result = await callAPIGetAllEmployeesForCompany();
      if (result.status === 200) {
        tableRowsColumns = await generateOperatorsTableColumnsRowsData(result.data.data);
        setTableData(tableRowsColumns);
        setIsLoading(false);
        setIsError(false);
      } else {
        tableRowsColumns = await generateOperatorsTableColumnsRowsData([]);
        setTableData(tableRowsColumns);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Operator listesi yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      tableRowsColumns = await generateOperatorsTableColumnsRowsData([]);
      setTableData(tableRowsColumns);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Operator listesi yüklenirken bir hata oluştu.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Operator Listesi
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                  />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default ListOperators;
