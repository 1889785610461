
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import AirlineSeatLegroomExtraTwoToneIcon from '@mui/icons-material/AirlineSeatLegroomExtraTwoTone';
//Link
import {Link} from "react-router-dom";

//Utils
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';

export const generateOperatorsTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%"
    },
    {
      Header: "İsim",
      accessor: "name",
      align: "left"
    },
    {
      Header: "Soyisim",
      accessor: "surname",
      align: "left"
    },
    {
      Header: "",
      accessor: "action",
      align: "center",
    },
  ];

  let rows = [];

  data.forEach(d => {
    const {name,surname,id} = d;
    rows.push({
      icon: (
        <PersonIcon color="info" fontSize='medium'/>
      ),
      name,
      surname,
      action: (
        <MDTypography component="a" href="#" variant="h5" color="text" fontWeight="medium">
          <Link to={{
            pathname: `/create-edit-operator?mode=edit&operatorId=${id}`
          }}>
        <EditIcon color="infp"/>
          </Link>
        </MDTypography>
      )
    });
  })

  return {
    rows,columns
  }
}
