// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Page components
import DetailsPageRecentContactUpdateHistoryTimeline from "components/CustomComponents/DetailsPageCards/DetailsPageRecentContactUpdateHistoryTimeline";
import DetailsPageInfoDetailsCard from "components/CustomComponents/DetailsPageCards/DetailsPageInfoDetailsCard";
import DetailsPageContactPeopleCard from "../../../components/CustomComponents/DetailsPageCards/DetailsPageContactPeopleCard";
import DetailsPageHeaders from '../../../components/CustomComponents/DetailsPageHeaders/DetailsPageHeaders';
import ErrorMessage from '../../../components/CustomComponents/Notifications/ErrorMessage';

//React
import {useEffect,useState} from 'react';

//API Calls
import {
  callAPIGetLast5ForwardersStatusUpdates
} from '../../../apiCalls/forwarderStatusUpdatesApiCalls';
import { callAPIGetForwarderContacts } from '../../../apiCalls/forwarderContactsApiCalls';

//Constants
import { callAPIGetCustomerDetails } from '../../../apiCalls/customerApiCalls';
import { callAPIGetCustomerContacts } from '../../../apiCalls/customerContactsApiCalls';
import { callAPIGetLast5CustomerStatusUpdates } from '../../../apiCalls/customerStatusUpdatesApiCalls';

//React router
import {useLocation} from "react-router-dom";

function CustomerDetails() {

  const [isError , setIsError ] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [customerDetails , setCustomerDetails] = useState({
    shortName:'',
    name: '',
    email: '',
    phone: '',
    taxId: '',
    country: '',
    city: '',
    address: '',
    website: '',
    taxOffice: ''
  });

  const [customerContacts,setCustomerContacts] = useState([]);

  const [customerStatusUpdates,setCustomerStatusUpdates] = useState([]);

  const paramsFromNavigation = useLocation();
  const customerId = paramsFromNavigation.search.replace('?','');

  useEffect(()=>{
    getCustomerDetails();
    getCustomerContacts();
    getLast5CustomerStatusUpdates();
  },[]);



  const getLast5CustomerStatusUpdates = async ()=> {
    try {
      const result = await callAPIGetLast5CustomerStatusUpdates({customerId});
      if (result.status === 200) {
        setCustomerStatusUpdates(result.data.data);
        setIsLoading(false);
        setIsError(false);
      }
      else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Nakliyeci görüşmeleri yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Nakliyeci görüşmeleri yüklenirken bir hata oluştu.');
    }
  }

  const getCustomerDetails = async ()=> {
    try {
      const result = await callAPIGetCustomerDetails({customerId});
      if (result.status === 200) {
        setCustomerDetails(result.data.data);
        setIsLoading(false);
        setIsError(false);
      }
      else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Müşteri detaylari yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Müşteri detaylari yüklenirken bir hata oluştu.');
    }
  }

  const getCustomerContacts = async ()=> {
    try {
      const result = await callAPIGetCustomerContacts({customerId});
      if (result.status === 200) {
        setCustomerContacts(result.data.data);
        setIsLoading(false);
        setIsError(false);
      }
      else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Müşteri kisi listesi yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Müşteri kisi listesi yüklenirken bir hata oluştu.');
    }

  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <DetailsPageHeaders  linkForEdit={`/create-edit-customer?customerId=${customerDetails.id}&mode=edit`}  icon="business_center_two_tone" name={!isLoading && customerDetails.name === '' ? customerDetails.shortName : customerDetails.name} type="Müşteri">
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Müşteri Bilgileri"
                info={{
                  Isim: customerDetails.shortName,
                  Telefon: customerDetails.phone,
                  email: customerDetails.phone,
                  adres: customerDetails.address,
                  ulke: customerDetails.country+ '  ' + customerDetails.city,
                  website: customerDetails.website,
                  vergiDairesi: customerDetails.taxOffice,
                  vergiNo: customerDetails.taxId
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <DetailsPageContactPeopleCard isLoading={isLoading} title="İrtibat Kişileri" contacts={customerContacts || null} shadow={false} />
              <Divider orientation="vertical" sx={{ ml: 16, mr: 1 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <DetailsPageRecentContactUpdateHistoryTimeline isLoading={isLoading} statusUpdates= {customerStatusUpdates || null}/>
            </Grid>
          </Grid>
        </MDBox>
      </DetailsPageHeaders>
      <ErrorMessage close={()=>setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default CustomerDetails;
