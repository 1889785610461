import { baseURL, callDELETEMethodAPI } from "./index";
import { ENTITY_NAMES } from "../constants/entityNames";

export const callAPIDeleteEntity = async (entityType, id) => {
    let modifiedEntityType = `${entityType}s`;
    if(entityType === ENTITY_NAMES.company) modifiedEntityType = "companies";

  const url = `${baseURL}/${modifiedEntityType}/remove/${parseInt(id)}`;
  return callDELETEMethodAPI(url);
};

