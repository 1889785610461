export const inputFieldsMeta = [
  {
    fieldName: 'name',
    errorMessage: `İsim zorunlu alan.Lütfen doldurunuz.`,
    label: 'İsim',
    isRequired:true,
    isFullWidth:true,
    type: 'text',
    componentType: 'input'
  },
  {
    fieldName: 'surname',
    label: 'Soyisim',
    errorMessage: `Soyisim zorunlu alan.Lütfen doldurunuz.`,
    isRequired:true,
    isFullWidth:true,
    type: 'text',
    componentType: 'input'
  }
]