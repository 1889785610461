// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// React
import { useEffect, useState } from "react";

// Data and API Calls
import { callAPIGetVehicles } from "../../../apiCalls/vehicleApiCalls";
import { generateVehicleTableColumnsRowsData } from "./vehicleDataTable";

// Custom Library
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import LoaderSpinner from "../../../components/CustomComponents/Loader/LoaderSpinner";

function ListVehicles(props) {
  const [vehicleType, setVehicleType] = useState(props.entity);
  const [tableData, setTableData] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const vehicleLabels = {
    truck: "TIR",
    towTruck: "Dorse",
    trailer: "Cekici",
  };

  useEffect(() => {
    setVehicleType(props.entity);
    getVehiclesAllOrByType();
  }, [props.entity, vehicleType]);

  const getVehiclesAllOrByType = async () => {
    let tableRowsColumns;
    try {
      let result;

      vehicleType
        ? (result = await callAPIGetVehicles({ entity: vehicleType }))
        : (result = await callAPIGetVehicles());

      if (result.status === 200) {
        tableRowsColumns = await generateVehicleTableColumnsRowsData(result.data.data);
        setTableData(tableRowsColumns);
        setIsLoading(false);
        setIsError(false);
      } else {
        tableRowsColumns = await generateVehicleTableColumnsRowsData([]);
        setTableData(tableRowsColumns);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          `${
            vehicleType ? vehicleLabels[vehicleType] : "Araç"
          } listesi yüklenirken bir hata oluştu. Hata detayı: `,
          result.data.message
        );
      }
    } catch (err) {
      tableRowsColumns = await generateVehicleTableColumnsRowsData([]);
      setTableData(tableRowsColumns);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(
        `${vehicleType ? vehicleLabels[vehicleType] : "Araç"} listesi yüklenirken bir hata oluştu.`
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {vehicleType ? vehicleLabels[vehicleType] : "Araç"} Listesi
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <LoaderSpinner />
                ) : (
                  <DataTable
                    isSorted={false}
                    pagination={{ variant: "gradient", color: "info" }}
                    table={tableData}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}
export default ListVehicles;
