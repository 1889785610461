import {
  callPUTMethodAPI,
  objectToQueryString,
  baseURL,
  callGETMethodAPI,
  callPOSTMethodAPI,
  callDELETEMethodAPI,
} from "./index";

export const callAPIGetAllDriversForCompany = async (params) => {
  const url = `${`${baseURL}/drivers/getAllDriversForCompany` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIGetAllDriversDropdownView = async (params) => {
  const url = `${`${baseURL}/drivers/getAllDriversDropdownView` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPICreateNewDriver = (params, body) => {
  const url = `${`${baseURL}/drivers/createNewDriver` + "?"}${objectToQueryString(params)}`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetDriverDetails = async (params) => {
  const url = `${`${baseURL}/drivers/getDriverDetails` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIUpdateDriver = async (params, body) => {
  const url = `${`${baseURL}/drivers/updateDriver` + "?"}${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};
