export function generateMonthlyChartData(chartData) {
  const data = {
    labels: [],
    datasets: {
      label: "",
      data: [],
    },
  };

  chartData.forEach((d) => {
    const monthLabel = generateMonthLabel(d.mon);
    data.labels.push(monthLabel);
    data.datasets.data.push(d.contractCount);
  });

  return data;
}

function generateMonthLabel(month) {
  month = parseInt(month, 10);
  const months = {
    1: "Ocak",
    2: "Şubat",
    3: "Mart",
    4: "Nisan",
    5: "Mayıs",
    6: "Haziran",
    7: "Temmuz",
    8: "Ağustos",
    9: "Eylül",
    10: "Ekim",
    11: "Kasım",
    12: "Aralık",
  };
  return months[month];
}
