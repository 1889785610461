// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Page components
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DetailsPageInfoDetailsCard from "../../../components/CustomComponents/DetailsPageCards/DetailsPageInfoDetailsCard";
import DetailsPageHeaders from "../../../components/CustomComponents/DetailsPageHeaders/DetailsPageHeaders";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";

// React router
import {
  callAPIGetContractDetails,
  callAPIUpdateContract,
} from "../../../apiCalls/contractApiCalls";
import { returnShortDateString } from "../../../utils/dateUtils";

import { convertNullValuesToEmptyString } from "../../../utils/dataUtils";
import MDTypography from "../../../components/MDTypography";

function ContractDetails() {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [contractDetails, setContractDetails] = useState({});

  const paramsFromNavigation = useLocation();
  const contractId = paramsFromNavigation.search.replace("?", "");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getContractDetails();
  }, []);

  const getContractDetails = async () => {
    try {
      const result = await callAPIGetContractDetails({ contractId });
      if (result.status === 200) {
        const convertedDataSet = await convertNullValuesToEmptyString(result.data.data[0]);
        setContractDetails(convertedDataSet);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(t("errorMessages.contractLoadingError"), result.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(t("errorMessages.contractLoadingError"));
    }
  };

  const updatePayment = async (paymentType) => {
    let updatedContractBody = {
      id: contractDetails.id,
    };

    if (paymentType === "forwarderPayment") {
      updatedContractBody = {
        ...updatedContractBody,
        forwarderPaymentReceived: !contractDetails.forwarderPaymentReceived,
      };
    } else {
      updatedContractBody = {
        ...updatedContractBody,
        customerPaymentReceived: !contractDetails.customerPaymentReceived,
      };
    }

    try {
      const resultUpdatedContract = await callAPIUpdateContract(null, updatedContractBody);
      if (resultUpdatedContract.status === 200) {
        window.location.reload(false);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Taşıma güncellenirken bir hata oluştu. Hata detayı: ",
          resultUpdatedContract.data.message
        );
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Taşıma güncellenirken bir hata oluştu.");
    }
  };

  const renderUpdatePaymentLink = (paymentType) => {
    let paymentReceivedData = contractDetails.forwarderPaymentReceived;
    let label = "Nakliyeci";
    if (paymentType === "customerPayment") {
      paymentReceivedData = contractDetails.customerPaymentReceived;
      label = "Musteri";
    }
    return (
      <MDBox>
        <MDTypography
          onClick={() => updatePayment(paymentType)}
          variant="button"
          fontWeight="regular"
          color="info"
        >
          {`${label} Tahsilat durumunu ${
            paymentReceivedData ? " 'Tahsilat Yapilmadi' " : " 'Tahsilat Yapildi' "
          } olarak degistirmek icin tiklayin`}
        </MDTypography>
      </MDBox>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <DetailsPageHeaders
        linkForCopy={`/create-edit-contract?contractId=${contractDetails.id}&mode=copy`}
        linkForEdit={`/create-edit-contract?contractId=${contractDetails.id}&mode=edit`}
        icon="assignment_two_tone_icon"
        name={
          !isLoading &&
          `${t("contractDetails.contractNoHeader")}: ${contractDetails.contractNo}  ${
            contractDetails.contractType ? `(${contractDetails.contractType})` : ""
          } - ${returnShortDateString(contractDetails.contractDate)}`
        }
        type={`Müşteri: ${contractDetails.customerName}`}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title={t("contractDetails.loadingDetailsHeader")}
                info={{
                  yuklemeUlkesi: contractDetails.loadingCountry,
                  cikisTarihi:
                    contractDetails.departingDate &&
                    returnShortDateString(contractDetails.departingDate),
                  cikisAdresi: contractDetails.departingAddress,
                  cikisGumrugu: contractDetails.departingCustom,
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />

              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Varış Bilgileri"
                info={{
                  varisUlkesi: contractDetails.offLoadingCountry,
                  varisTarihi:
                    contractDetails.arrivingDate &&
                    returnShortDateString(contractDetails.arrivingDate),
                  varisAdresi: contractDetails.arrivingAddress,
                  varisGumrugu: contractDetails.arrivingCustom,
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />

              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Yük Detaylari"
                info={{
                  detay: contractDetails.freightDetails,
                  boyutlar: `En(cm):  ${contractDetails.width || "-"}  Boy(cm):  ${
                    contractDetails.length || "-"
                  }   Yk(cm):  ${contractDetails.height || "-"} `,
                  agirlik: `${contractDetails.weight ? `${contractDetails.weight} kg` : ""}`,
                  adet: contractDetails.quantity,
                  yukTipi: contractDetails.freightType,
                  aracTipi: contractDetails.transportType,
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{ ml: -2, mr: 2 }} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Taşıma Bilgileri"
                info={
                  contractDetails.forwardOrOwnTransport === "OWN"
                    ? {
                        cekiciPlaka: contractDetails.trailerPlateNumber,
                        dorsePlaka: contractDetails.towTruckPlateNumber,
                        sofor: `${contractDetails.driverName || ""} ${
                          contractDetails.driverSurname || ""
                        }`,
                        operasyoncu: `${contractDetails.operatorName || ""} ${
                          contractDetails.operatorSurname || ""
                        }`,
                      }
                    : {
                        nakliyeci: contractDetails.forwarderName,
                        nakliyeciAdresi: `${contractDetails.forwarderAddress} ${contractDetails.forwarderCity} ${contractDetails.forwarderCountry}`,
                        vergiBilgileri: `${contractDetails.forwarderTaxOffice} ${contractDetails.forwarderTaxId}`,
                        iletisimBilgileri: `${contractDetails.forwarderPhone} ${contractDetails.forwarderEmail}`,
                        plaka: contractDetails.forwarderPlateNumber,
                        sofor: `${contractDetails.forwarderDriverName} ${contractDetails.forwarderDriverSurname}`,
                        soforPasaport: contractDetails.forwarderDriverPassportIDNumber,
                        operasyoncu: `${contractDetails.operatorName} ${contractDetails.operatorSurname}`,
                      }
                }
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />

              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Müşteri Bilgileri"
                info={{
                  musteri: contractDetails.customerName,
                  musteriAdresi: `${contractDetails.customerAddress} ${contractDetails.customerCity} ${contractDetails.customerCountry}`,
                  vergiDairesi: contractDetails.customerTaxOffice,
                  vergiNo: contractDetails.customerTaxId,
                  telefon: contractDetails.customerPhone,
                  email: contractDetails.customerEmail,
                }}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 2 }} />

              <DetailsPageInfoDetailsCard
                isLoading={isLoading}
                title="Fiyat Bilgileri"
                info={
                  contractDetails.forwardOrOwnTransport === "OWN"
                    ? {
                        musteriFiyati: `${contractDetails.customerInvoicePriceValue} ${contractDetails.customerInvoiceCurrency}`,
                        musteriVadesi: `${contractDetails.customerInvoiceDueInDays || "0"} gün`,
                        musteriOdemeDurumu: `${
                          contractDetails.customerPaymentReceived ? "ALINDI" : "ALINMADI"
                        }`,
                      }
                    : {
                        musteriFiyati: `${contractDetails.customerInvoicePriceValue} ${contractDetails.customerInvoiceCurrency}`,
                        musteriVadesi: `${contractDetails.customerInvoiceDueInDays || "0"} gün`,
                        nakliyeciFiyati: `${contractDetails.forwarderInvoicePriceValue} ${contractDetails.forwarderInvoiceCurrency}`,
                        nakliyeciVadesi: `${contractDetails.forwarderInvoiceDueInDays || "0"} gün`,
                        musteriOdeme: `${
                          contractDetails.customerPaymentReceived
                            ? "TAHSILAT YAPILDI"
                            : "TAHSILAT YAPILMADI"
                        }`,
                        nakliyeciOdeme: `${
                          contractDetails.forwarderPaymentReceived
                            ? "TAHSILAT YAPILDI"
                            : "TAHSILAT YAPILMADI"
                        }`,
                      }
                }
                additionalInfo={
                  <>
                    {renderUpdatePaymentLink("customerPayment")}
                    {contractDetails.forwardOrOwnTransport === "FORWARDER" &&
                      renderUpdatePaymentLink("forwarderPayment")}
                  </>
                }
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
            </Grid>

            {/* <Grid item xs={12} md={6} xl={4}  sx={{ display: "flex" }}>
              <FileDownloadIcon fontSize="small" color="info"/>
              <MDTypography   variant="button" fontWeight="regular" color="info">
                &nbsp; Müşteri Sözleşmesini Yükle
              </MDTypography>
            </Grid>
            {contractDetails.forwardOrOwnTransport !== "OWN" &&
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <FileDownloadIcon fontSize="small" color="info"/>
              <MDTypography   variant="button" fontWeight="regular" color="info">
                &nbsp; Nakliyeci Sözleşmesini Yükle
              </MDTypography>
            </Grid>
            } */}
          </Grid>
        </MDBox>
      </DetailsPageHeaders>
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default ContractDetails;
