import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetLast5ForwardersStatusUpdates = async (params) => {
  const url = `${
    `${baseURL}/forwarderStatusUpdates/getLast5ForwarderStatusUpdates` + "?"
  }${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPICreateNewForwarderStatusUpdate = async (params, body) => {
  const url = `${
    `${baseURL}/forwarderStatusUpdates/createNewForwarderStatusUpdate` + "?"
  }${objectToQueryString(params)}`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetForwardersStatusUpdateDetails = async (params) => {
  const url = `${
    `${baseURL}/forwarderStatusUpdates/getForwarderStatusUpdateDetails` + "?"
  }${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIUpdateForwardersStatusUpdate = async (params, body) => {
  const url = `${
    `${baseURL}/forwarderStatusUpdates/updateForwarderStatusUpdate` + "?"
  }${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};
