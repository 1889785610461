
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";


//Custom Library
import ErrorMessage from '../../../components/CustomComponents/Notifications/ErrorMessage';
import LoaderSpinner from '../../../components/CustomComponents/Loader/LoaderSpinner';
import { callAPIGetAllForwarderContracts, callAPIGetAllOwnContracts } from '../../../apiCalls/contractApiCalls';
import { generateOwnersContractTableColumnsRowsData } from '../../Common/ownerContractDataTable';
import { generateForwardersContractTableColumnsRowsData } from '../../Common/forwarderContractDataTable';


function ListContracts() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [isLoading,setIsLoading]= useState(true);
  const [errorMessage,setErrorMessage] = useState("");
  const [isError,setIsError] =useState(false);
  const [ownContractData,setOwnContractData] = useState();
  const [forwarderContractData,setForwarderContractData] = useState();

  useEffect(async () => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    await getAllOwnContractsOfCompany();
    await getAllForwarderContractsOfCompany();
    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    await setIsLoading(false);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const getAllOwnContractsOfCompany = async ()=> {
    let tableRowsColumns;
    try {
      const result = await callAPIGetAllOwnContracts();
      if (result.status === 200) {
        tableRowsColumns = await generateOwnersContractTableColumnsRowsData(result.data.data);
        setOwnContractData(tableRowsColumns);
        setIsError(false);
      }
      else {
        tableRowsColumns = await generateOwnersContractTableColumnsRowsData([]);
        setOwnContractData(tableRowsColumns);
        setIsError(true);
        setErrorMessage('Ozmal taşıma listesi yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      tableRowsColumns = await generateOwnersContractTableColumnsRowsData([]);
      setOwnContractData(tableRowsColumns);
      setIsError(true);
      setErrorMessage('Ozmal taşıma listesi yüklenirken bir hata oluştu.');
    }
  }


  const getAllForwarderContractsOfCompany = async ()=> {
    let tableRowsColumns;
    try {
      const result = await callAPIGetAllForwarderContracts();
      if (result.status === 200) {
        tableRowsColumns = await generateForwardersContractTableColumnsRowsData(result.data.data);
        setForwarderContractData(tableRowsColumns);
        setIsError(false);
      }
      else {
        tableRowsColumns = await generateForwardersContractTableColumnsRowsData([]);
        setForwarderContractData(tableRowsColumns);
        setIsError(true);
        setErrorMessage('Ozmal taşıma listesi yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      tableRowsColumns = await generateForwardersContractTableColumnsRowsData([]);
      setForwarderContractData(tableRowsColumns);
      setIsError(true);
      setErrorMessage('Ozmal taşıma listesi yüklenirken bir hata oluştu.');
    }

  }

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <Card>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6} lg={12} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Nakliyeci Taşımaları"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        assignment_two_tone_icon
                      </Icon>
                    }
                  />
                  <Tab
                    label="Ozmal Taşımaları"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        assignment_two_tone_icon
                      </Icon>
                    }
                  />

                </Tabs>
              </AppBar>
              <TabPanel isLoading={isLoading} title="Ozmal Taşımaları Listesi" tableData={ownContractData} value={tabValue} index={1}/>
              <TabPanel isLoading={isLoading} title="Nakliyeci Taşımaları Listesi" tableData={forwarderContractData} value={tabValue} index={0}/>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <ErrorMessage close={()=>setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>

  );
}

function TabPanel(props) {
  const { value, index ,tableData,title,isLoading} = props;

  return (
    <Card>
    {value === index && (
      <>
          <MDBox
            mx={2}
            mt={5}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {title}
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            { isLoading ?
              <LoaderSpinner/>
              :(
                <DataTable
                  isSorted={false}
                  canSearch={true}
                  pagination={ {variant: "gradient", color: "info" }}
                  table={tableData}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              )}
          </MDBox>
      </>
        )}
    </Card>
  );
}

export default ListContracts;
