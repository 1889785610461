/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Components
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";

function DetailsInfoDetailsCard({
  isLoading,
  title,
  description,
  info,
  action,
  shadow,
  additionalInfo,
}) {
  const labels = [];
  const values = [];
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title || "Bilgiler"}
        </MDTypography>
        <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          {/* <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip> */}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <MDBox>
            {renderItems}
            {additionalInfo}
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
DetailsInfoDetailsCard.defaultProps = {
  shadow: true,
  isLoading: true,
};

// Typechecking props for the ProfileInfoCard
DetailsInfoDetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  /*
  description: PropTypes.string.isRequired,
*/
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DetailsInfoDetailsCard;
