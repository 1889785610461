import MDBox from 'components/MDBox';

export function FormFileUploadField(props) {
    
    const { register, metaData: { label, isRequired, topMargin, maxLength } } = props;

    let { metaData: { fieldName,docTypeField,docType } } = props;

    const handleUploader = (e) => {
        if(e.target.files[0].size > 5242880){
            alert("Dosya boyutu 5 MB'yi gecmemelidir!");
            e.target.value = null;
         };
    }

    return (
        <>
            <MDBox mt={topMargin || 2}>
        
            <input 
                style={{marginLeft:50,marginTop:25}}
                type="file"
                onInput={handleUploader}
                {...register(fieldName, {required: isRequired,maxLength: maxLength||1000})}
                label={label}  
                variant="standard"
                accept=".png,.jpeg,.jpg,.pdf,.zip,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" 
          /> 
            </MDBox>
            <input 
                style={{visibility:'hidden'}}

                {...register(docTypeField, {value: docType,required: isRequired})}
              /> 
        </>
    )
}
