export const currencies = ["PLN", "TRY", "USD", "EUR"];
export const freightTypes = ["Gabari", "Normal", "Parsiyel", "Frigo"];
export const invoiceTypes = ["İthalat", "İhracat", "Transit", "Dahili"];
export const transportTypes = [
  "Tente",
  "Frigo",
  "Low-bed",
  "Minivan",
  "Megatente",
  "Kapali Kasa",
  "Solo",
];
export const defaultCurrency = currencies[3];
export const countries = [
  "Afganistan",
  "Almanya",
  "Amerika Birleşik Devletleri",
  "Andora",
  "Angola",
  "Antigua ve Barbuda",
  "Arjantin",
  "Arnavutluk",
  "Avustralya",
  "Avusturya",
  "Azerbaycan",
  "Bahamalar",
  "Bahreyn",
  "Bangladeş",
  "Barbados",
  "Batı Sahra",
  "Belçika",
  "Belize",
  "Benin",
  "Beyaz Rusya",
  "Bhutan",
  "Birleşik Arap Emirlikleri",
  "Bolivya",
  "Bosna-Hersek",
  "Botsvana",
  "Brezilya",
  "Brunei",
  "Bulgaristan",
  "Burkina Faso",
  "Burundi",
  "Cezayir",
  "Cibuti",
  "Çad",
  "Çek Cumhuriyeti",
  "Çin Halk Cumhuriyeti",
  "Dağlık Karabağ Cumhuriyeti",
  "Danimarka",
  "Doğu Timor",
  "Dominik Cumhuriyeti",
  "Dominika",
  "Ekvador",
  "Ekvator Ginesi",
  "El Salvador",
  "Endonezya",
  "Eritre",
  "Ermenistan",
  "Estonya",
  "Etiyopya",
  "Fas",
  "Fiji",
  "Fildişi Sahili",
  "Filipinler",
  "Filistin",
  "Finlandiya",
  "Fransa",
  "Gabon",
  "Gambiya",
  "Gana",
  "Gine",
  "Gine Bissau",
  "Grenada",
  "Guyana",
  "Guatemala",
  "Güney Afrika Cumhuriyeti",
  "Güney Kore",
  "Güney Osetya",
  "Güney Sudan",
  "Gürcistan",
  "Haiti",
  "Hırvatistan",
  "Hindistan",
  "Hollanda",
  "Honduras",
  "Irak",
  "İngiltere",
  "İran",
  "İrlanda",
  "İspanya",
  "İsrail",
  "İsveç",
  "İsviçre",
  "İtalya",
  "İzlanda",
  "Jamaika",
  "Japonya",
  "Kamboçya",
  "Kamerun",
  "Kanada",
  "Karadağ",
  "Katar",
  "Kazakistan",
  "Kenya",
  "Kırgızistan",
  "Kıbrıs Cumhuriyeti",
  "Kiribati",
  "Kolombiya",
  "Komorlar",
  "Kongo",
  "Kongo Demokratik Cumhuriyeti",
  "Kosova",
  "Kostarika",
  "Kuveyt",
  "Kuzey Kıbrıs Türk Cumhuriyeti",
  "Kuzey Kore",
  "Küba",
  "Laos",
  "Lesotho",
  "Letonya",
  "Liberya",
  "Libya",
  "Lihtenştayn",
  "Litvanya",
  "Lübnan",
  "Lüksemburg",
  "Macaristan",
  "Madagaskar",
  "Makedonya Cumhuriyeti",
  "Malavi",
  "Maldiv Adaları",
  "Malezya",
  "Mali",
  "Malta",
  "Marshall Adaları",
  "Meksika",
  "Mısır",
  "Mikronezya",
  "Moğolistan",
  "Moldova",
  "Monako",
  "Moritanya",
  "Moritius",
  "Mozambik",
  "Myanmar",
  "Namibya",
  "Nauru",
  "Nepal",
  "Nikaragua",
  "Nijer",
  "Nijerya",
  "Norveç",
  "Orta Afrika Cumhuriyeti",
  "Özbekistan",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua Yeni Gine",
  "Paraguay",
  "Peru",
  "Polonya",
  "Portekiz",
  "Romanya",
  "Ruanda",
  "Rusya Federasyonu",
  "Saint Kitts ve Nevis",
  "Saint Lucia",
  "Saint Vincent ve Grenadinler",
  "Samoa",
  "San Marino",
  "Sao Tome ve Principe",
  "Sealand",
  "Senegal",
  "Seyşeller",
  "Sırbistan",
  "Sierra Leone",
  "Singapur",
  "Slovakya",
  "Slovenya",
  "Solomon",
  "Somali",
  "Somaliland",
  "Sri Lanka",
  "Sudan",
  "Surinam",
  "Suriye",
  "Suudi Arabistan",
  "Svaziland",
  "Şili",
  "Tacikistan",
  "Tanzanya",
  "Tayland",
  "Tayvan",
  "Togo",
  "Tonga",
  "Transdinyester",
  "Trinidad ve Tobago",
  "Tunus",
  "Tuvalu",
  "Türkiye",
  "Türkmenistan",
  "Uganda",
  "Ukrayna",
  "Umman",
  "Uruguay",
  "Ürdün",
  "Vanuatu",
  "Vatikan",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Yeni Zelanda",
  "Yeşil Burun",
  "Yunanistan",
  "Zambiya",
];
export const vehicleEntities = ["truck", "towTruck", "trailer"];
export const visaCountries = ["Schengen", ...countries];
