import { Fragment, React, useRef, useEffect, useState } from "react";
import Form from "components/CustomComponents/CreateEditForms/Form";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";
import { inputFieldsMeta, nrOfFieldsInContacts } from "./inputFieldsMeta";
import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";

import {
  callAPICreateCustomerContacts,
  callAPIGetCustomerContacts,
  callAPIUpdateCustomerContacts,
} from "../../../apiCalls/customerContactsApiCalls";
import {
  callAPICreateNewCustomer,
  callAPIGetCustomerDetails,
  callAPIUpdateCustomer,
} from "../../../apiCalls/customerApiCalls";

function CreateEditCustomer() {
  let alreadySelectedDropdownValues = {};
  let existingCustomerValues = {};
  let existingCustomerContactValues = {};

  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [inputFieldsMetaData, setInputFieldsMetaData] = useState(null);

  const getCustomerContacts = async () => {
    const id = urlParams.searchParams.get("customerId");
    try {
      const customerContactResponse = await callAPIGetCustomerContacts({
        customerId: id,
      });
      if (customerContactResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return customerContactResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Müşteri irtibat kisileri yüklenirken bir hata oluştu. Hata detayı: ",
        customerContactResponse.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Müşteri irtibat kisileri yüklenirken bir hata oluştu.");
    }
  };

  const getCustomerDetails = async () => {
    const id = urlParams.searchParams.get("customerId");
    try {
      const customerDetailsResponse = await callAPIGetCustomerDetails({
        customerId: id,
      });
      if (customerDetailsResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return customerDetailsResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Müşteri yüklenirken bir hata oluştu. Hata detayı: ", result.data.message);
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Müşteri yüklenirken bir hata oluştu.");
    }
  };

  const getDropdownIndexesForExistingValues = async () => {
    const { country } = existingCustomerValues;
    if (country) {
      alreadySelectedDropdownValues.country = country;
    }
  };

  const setExistingValuesForForm = async () => {
    for (const [key, value] of Object.entries(existingCustomerValues)) {
      if (
        key !== "id" ||
        value !== null ||
        value !== "" ||
        key === "createdAt" ||
        key === "updatedAt"
      ) {
        formRef.current.setValue(key, value);
      }
    }

    for (let i = 0; i < existingCustomerContactValues.length; i++) {
      for (const [key, value] of Object.entries(existingCustomerContactValues[i])) {
        if (
          key !== "id" ||
          value !== null ||
          value !== "" ||
          key === "createdAt" ||
          key === "updatedAt"
        ) {
          formRef.current.setValue(`customerContacts.${key}${i + 1}`, value);
        }
      }
    }
  };

  useEffect(async () => {
    if (mode === "edit" || mode==="copy") {
      existingCustomerValues = await getCustomerDetails();
      existingCustomerContactValues = await getCustomerContacts();
      await getDropdownIndexesForExistingValues();
      await setInputFieldsMetaData(
        inputFieldsMeta({
          contactSize: existingCustomerContactValues.length || 1,
          alreadySelectedDropdownValues: alreadySelectedDropdownValues,
        })
      );
      await setExistingValuesForForm();
      setIsLoading(false);
    } else {
      await setInputFieldsMetaData(inputFieldsMeta({}));
      setIsLoading(false);
    }
  }, []);

  const customerContactHasValue = async (customerContacts, counter) => {
    const valuesAreNotEmpty = !(
      customerContacts[`name${counter + 1}`] === "" &&
      customerContacts[`surname${counter + 1}`] === "" &&
      customerContacts[`phone${counter + 1}`] === "" &&
      customerContacts[`email${counter + 1}`] === ""
    );
    const valuesAreNotUndefined = !(
      customerContacts[`name${counter + 1}`] === undefined &&
      customerContacts[`surname${counter + 1}`] === undefined &&
      customerContacts[`phone${counter + 1}`] === undefined &&
      customerContacts[`email${counter + 1}`] === undefined
    );

    return valuesAreNotEmpty && valuesAreNotUndefined;
  };

  const customerContactsToBeSaved = async ({ customerContacts, customerId }) => {
    // Get total count of contacts. If update mode there are 4 more fields
    const totalNewContactCount =
      Object.keys(customerContacts).length /
      (mode === "edit" ? nrOfFieldsInContacts + 4 : nrOfFieldsInContacts);
    const customerContactsData = [];

    for (let i = 0; i < totalNewContactCount; i++) {
      // Don't create if there is no data
      if ((await customerContactHasValue(customerContacts, i)) === true) {
        const customerContact = {
          name: customerContacts[`name${i + 1}`],
          surname: customerContacts[`surname${i + 1}`],
          phone: customerContacts[`phone${i + 1}`],
          email: customerContacts[`email${i + 1}`],
          customerId,
        };
        customerContactsData.push(customerContact);
      }
    }
    return customerContactsData;
  };

  const createCustomerContacts = async ({ customerContacts, customerId }) => {
    const customerContactsData = await customerContactsToBeSaved({ customerContacts, customerId });

    if (customerContactsData.length !== 0) {
      try {
        const resultCreateNewCustomerContacts = await callAPICreateCustomerContacts(
          customerContactsData
        );
        if (resultCreateNewCustomerContacts.status !== 200) {
          setIsError(true);
          setErrorMessage(
            "Müşteri kontaklari oluşturulurken bir hata oluştu. Hata detayı: ",
            result.data.message
          );
        }
      } catch (err) {
        setIsError(true);
        setErrorMessage("Nakliyeci kontaklari oluşturulurken bir hata oluştu.");
      }
    }
  };

  const updateCustomerContacts = async ({ customerContacts, customerId }) => {
    const customerContactsData = await customerContactsToBeSaved({ customerContacts, customerId });

    if (customerContactsData.length !== 0) {
      try {
        const resultUpdateCustomerContacts = await callAPIUpdateCustomerContacts(
          null,
          customerContactsData
        );
        if (resultUpdateCustomerContacts.status !== 200) {
          setIsError(true);
          setErrorMessage(
            "Müşteri kontaklari güncellenirken bir hata oluştu. Hata detayı: ",
            result.data.message
          );
        }
      } catch (err) {
        setIsError(true);
        setErrorMessage("Müşteri kontaklari güncellenirken bir hata oluştu.");
      }
    }
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    if (mode === "edit") {
      updateCustomer(data);
    } else {
      createNewCustomer(data);
    }
  };

  const updateCustomer = async (data) => {
    const { customerContacts, ...updatedCustomerData } = data;

    try {
      const resultUpdatedCustomer = await callAPIUpdateCustomer(
null,        updatedCustomerData
      );
      if (resultUpdatedCustomer.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${data.shortName} isimli musteri başarıyla güncellendi.`);

        updateCustomerContacts({
          customerContacts,
          customerId: updatedCustomerData.id,
        });
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Müşteri güncellenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Müşteri güncellenirken bir hata oluştu.");
    }
  };

  const createNewCustomer = async (data) => {
    setIsLoading(true);

    const { customerContacts, ...newCustomerData } = data;

    try {
      const resultCreateNewCustomer = await callAPICreateNewCustomer(
        newCustomerData
      );
      if (resultCreateNewCustomer.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${newCustomerData.shortName} isimli musteri başarıyla oluşturuldu.`);

        createCustomerContacts({
          customerContacts,
          customerId: resultCreateNewCustomer.data.data.id,
        });
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Müşteri oluşturulurken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Müşteri oluşturulurken bir hata oluştu.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {inputFieldsMetaData ? (
        <Form
          ref={formRef}
          mode={mode}
          handleSave={handleSave}
          isLoading={isLoading}
          title={`Müşteri ${mode === "edit" ? "Güncelle" : "Oluştur"} `}
          inputFields={inputFieldsMetaData}
        />
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <SuccessMessage
        close={() => setIsSuccess(false)}
        successMessage={successMessage}
        show={isSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CreateEditCustomer;
