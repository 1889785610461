export const ENTITY_NAMES = {
  driver: 'driver',
  company: 'company',
  document: 'document',
  forwarder: 'forwarder',
  driverVisa: 'driverVisa',
  contract: 'contract',
  customer: 'customer',
  vehicle: 'vehicle'
};
