import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetCustomerContacts = async (params) => {
  const url = `${`${baseURL}/customerContacts/getCustomerContacts` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPICreateCustomerContacts = async (body) => {
  const url = `${baseURL}/customerContacts/createCustomerContacts`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIUpdateCustomerContacts = async (params, body) => {
  const url = `${`${baseURL}/customerContacts/updateCustomerContacts` + "?"}${objectToQueryString(
    params
  )}`;
  return callPUTMethodAPI(url, body);
};
