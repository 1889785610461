module.exports = {
  development : {
    apiUrl: "http://localhost:8000",
    appVersion: "1.2"
  },
  staging: {
    apiUrl: "https://logistic-contract-api-staging.herokuapp.com",
    appVersion: "1.2"
  },
  production: {
    apiUrl: "https://logistic-contract-api-prod.herokuapp.com",
    appVersion: "1.2"
  }
}