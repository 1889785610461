import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetAllForwardersForCompany = async (params) => {
  const url = `${`${baseURL}/forwarders/getAllForwardersForCompany` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPIGetAllForwardersDropdownView = async (params) => {
  const url = `${`${baseURL}/forwarders/getAllForwardersDropdownView` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPIGetForwarderDetails = async (params) => {
  const url = `${`${baseURL}/forwarders/getForwarderDetails` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPICreateNewForwarder = async (body) => {
  const url = `${baseURL}/forwarders/createNewForwarder`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIUpdateForwarder = async (params, body) => {
  const url = `${`${baseURL}/forwarders/updateForwarder` + "?"}${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};
