import {
  objectToQueryString,
  baseURL,
  callGETMethodAPI,
  callPOSTMethodAPI,
  callPUTMethodAPI,
} from "./index";

export const callAPIGetContractDetails = async (params) => {
  const url = `${`${baseURL}/contracts/getContractDetails` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPIGetContractDetailsForUpdate = async (params) => {
  const url = `${`${baseURL}/contracts/getContractDetailsForUpdate` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPICreateNewContract = async (body) => {
  const url = `${baseURL}/contracts/createNewContract`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetLastOwnContracts = async () => {
  const url = `${baseURL}/contracts/getLastOwnContracts`;
  return callGETMethodAPI(url);
};

export const callAPIGetLastForwarderContracts = async (params) => {
  const url = `${`${baseURL}/contracts/getLastForwarderContracts` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPIGetAllOwnContracts = async () => {
  const url = `${baseURL}/contracts/getAllOwnContracts`;
  return callGETMethodAPI(url);
};

export const callAPIGetAllForwarderContracts = async () => {
  const url = `${baseURL}/contracts/getAllForwarderContracts`;
  return callGETMethodAPI(url);
};

export const callAPIUpdateContract = async (params, body) => {
  const url = `${`${baseURL}/contracts/updateContract` + "?"}${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};

export const callAPIGetForwarderContractsInDue = async (params) => {
  const url = `${`${baseURL}/contracts/getForwarderContractsInDue` + "?"}${objectToQueryString(
    params
  )}`;
  return callGETMethodAPI(url);
};

export const callAPIGetAllContracts = async () => {
  const url = `${baseURL}/contracts/getAllContracts`;
  return callGETMethodAPI(url);
}
