import { baseURL, callGETMethodAPI , callPOSTMethodAPI} from "./index";

export const callAPICheckTokenValidity = async () => {
  const url = `${baseURL}/tokens/tokenValidity`;
  return callGETMethodAPI(url);
};

export const callAPIRevokeToken = async () => {
  const url = `${baseURL}/tokens/revokeToken`;
  return callPOSTMethodAPI(url);
};

