module.exports = {
  errorMessages: {
    contractLoadingError: 'Taşıma detaylari yüklenirken bir hata oluştu. Hata detayı: ',

  },
  contractDetails: {
    loadingDetailsHeader: 'Yükleme Detayları',
    contractNoHeader: 'Taşıma No',

  }
}
