/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

//React
import {useEffect} from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

//Utils
import {returnShortDateString} from "../../../utils/dateUtils";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import {Link} from 'react-router-dom';

//Components
import LoaderSpinner from "../Loader/LoaderSpinner";


function DetailsPageRecentContactUpdateHistoryTimeline({statusUpdates,isLoading,forwarderId,customerId,pathForNewItem}) {

  // Render the timeline info items
  const renderItems = statusUpdates.map((update, key) => (
    <TimelineItem
        updateId={update.id}
        path={pathForNewItem}
        color="info"
        icon="call_two_tone"
        title={update.comment}
        dateTime={returnShortDateString(update.updatedAt)}
        lastItem={key+1===statusUpdates.length}
      />
  ));


  let linkpathnameForCreateNew = '';

  if(customerId) linkpathnameForCreateNew = `${pathForNewItem}?customerId=${customerId}`;
  else if(forwarderId) linkpathnameForCreateNew = `${pathForNewItem}?forwarderId=${forwarderId}`;


  return (
    <Card sx={{ height: "100%", boxShadow: "none"  }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Son Görüşmeler
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            <Link to={{
              pathname: linkpathnameForCreateNew
            }}>
          <AddCircleIcon  color="info" fontSize="medium"  style={{marginLeft:35}}/>
            </Link>
        </MDTypography>
        </MDTypography>
      </MDBox>

      <MDBox p={2} pt={5}>
        {
          isLoading ? <LoaderSpinner/> : renderItems
        }
      </MDBox>
    </Card>
  );
}


// Setting default props for the ProfilesList
DetailsPageRecentContactUpdateHistoryTimeline.defaultProps = {
  isLoading: false,
};
DetailsPageRecentContactUpdateHistoryTimeline.propTypes = {
  statusUpdates: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.booleanValue
};

export default DetailsPageRecentContactUpdateHistoryTimeline;
