import {
  objectToQueryString,
  baseURL,
  callGETMethodAPI,
  callPOSTMethodAPI,
  callPUTMethodAPI,
} from "./index";

export const callAPIAddDocument = async (body) => {
  const url = `${baseURL}/documents/addDocument`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIDownloadDocument = async (params) => {
  const url = `${`${baseURL}/documents/downloadDocument` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url, true);
};

export const callAPIRemoveDocument = async (params, body) => {
  const url = `${`${baseURL}/documents/removeDocument` + "?"}${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};

export const callAPIListDocuments = async (params) => {
  const url = `${`${baseURL}/documents/listDocuments` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};
