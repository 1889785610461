// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

import { returnShortDateString } from "../../utils/dateUtils";
import { actionBaseColumns, baseColumns } from "./baseContractDataTable";
import { commonActionsColumn } from "./commonActionsColumnsInTable";
import { ENTITY_NAMES } from "constants/entityNames";

export const generateForwardersContractTableColumnsRowsData = async (data) => {
  const columns = [
    ...baseColumns,
    {
      Header: "Nakliye",
      accessor: "forwarder",
      align: "center",
    },
    {
      Header: "Nakliyeci Fiyati",
      accessor: "forwarderPrice",
      align: "center",
    },
    {
      Header: "Nakliyeci Ödeme Durumu",
      accessor: "forwarderPaymentStatus",
      align: "center",
    },
    ...actionBaseColumns,
  ];

  const rows = [];

  data.forEach((d) => {
    const {
      contractNo,
      operatorName,
      operatorSurname,
      customerInvoicePriceValue,
      customerInvoiceCurrency,
      forwarderInvoiceCurrency,
      forwarderInvoicePriceValue,
      departingDate,
      customerName,
      forwarderId,
      forwarderName,
      id,
      customerId,
      forwarderPaymentInDue,
      forwarderPaymentReceived,
      customerPaymentReceived,
      customerPaymentInDue,
    } = d;

    const actionColumnParams = {
      id,
      entity: ENTITY_NAMES.contract,
      isEditMode: true,
      isCopyMode: true,
      details: true,
      download: false,
      remove: true, // Enable remove action
      documents: true,
    };

    const forwarderPaymentStatus = forwarderPaymentReceived
      ? "Tahsil Edildi"
      : `Tahsil Edilmedi / ${forwarderPaymentInDue ? "Vadesi Geçti" : "Vadesi Gelmedi"}`;


    const customerPaymentStatus = customerPaymentReceived
      ? "Tahsil Edildi"
      : `Tahsil Edilmedi / ${customerPaymentInDue ? "Vadesi Geçti" : "Vadesi Gelmedi"}`;

    rows.push({
      contractNo,
      customer: (
        <MDTypography
          component="a"
          href={`customer-details?${customerId}`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {customerName}
        </MDTypography>
      ),
      forwarder: (
        <MDTypography
          component="a"
          href={`forwarder-details?${forwarderId}`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {forwarderName}
        </MDTypography>
      ),
      customerPrice: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {customerInvoicePriceValue} {customerInvoiceCurrency}
        </MDTypography>
      ),
      forwarderPrice: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {forwarderInvoicePriceValue} {forwarderInvoiceCurrency}
        </MDTypography>
      ),
      departingDate: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {departingDate && returnShortDateString(departingDate)}
        </MDTypography>
      ),
      customerPaymentStatus: (
        <MDTypography
          variant="caption"
          color={customerPaymentReceived ? "success" : customerPaymentInDue ? "error" : "info"}
          fontWeight="medium"
        >
          {customerPaymentStatus}
        </MDTypography>
      ),
      forwarderPaymentStatus: (
        <MDTypography
          variant="caption"
          color={forwarderPaymentReceived ? "success" : forwarderPaymentInDue ? "error" : "info"}
          fontWeight="medium"
        >{forwarderPaymentStatus}
        </MDTypography>
      ),
      operator: `${operatorName} ${operatorSurname}`,
      actions: commonActionsColumn(actionColumnParams),
    });
  });

  return {
    rows,
    columns,
  };
};
