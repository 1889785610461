// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

//React
import {useEffect,useState} from 'react';


//Custom Library
import ErrorMessage from 'components/CustomComponents/Notifications/ErrorMessage';
import LoaderSpinner from 'components/CustomComponents/Loader/LoaderSpinner';
import { callAPIGetForwarderContractsInDue } from '../../../../apiCalls/contractApiCalls';
import { generateForwarderContractsInDueTableColumnsRowsData } from './forwarderContractsInDueDataTable';



function ListForwarderDueContracts() {

  const [tableData,setTableData] = useState();
  const [isError , setIsError ] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    getForwarderContractsInDue();
  },[])


  const getForwarderContractsInDue = async ()=> {
    let tableRowsColumns;
    try {
      const result = await callAPIGetForwarderContractsInDue();
      if (result.status === 200) {
        tableRowsColumns = await generateForwarderContractsInDueTableColumnsRowsData(result.data.data);
        setTableData(tableRowsColumns);
        setIsLoading(false);
        setIsError(false);
      }
      else {
        tableRowsColumns = await generateForwarderContractsInDueTableColumnsRowsData([]);
        setTableData(tableRowsColumns);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage('Fatura listesi yüklenirken bir hata oluştu. Hata detayı: ', result.data.message);
      }
    }
    catch (err) {
      console.log(err);
      tableRowsColumns = await generateForwarderContractsInDueTableColumnsRowsData([]);
      setTableData(tableRowsColumns);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('Fatura listesi yüklenirken bir hata oluştu.');
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Müşteri Listesi
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                { isLoading ?
                  <LoaderSpinner/>
                  :(
                    <DataTable
                      isSorted={false}
                      canSearch={true}
                      pagination={{variant: "gradient", color: "info" }}
                      table={tableData}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ErrorMessage close={()=>setIsError(false)} errorMessage={errorMessage} show={isError} />
      <Footer />
    </DashboardLayout>
  );
}

export default ListForwarderDueContracts;
