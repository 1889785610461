import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useForm, setValue, control } from "react-hook-form";
import { forwardRef, useImperativeHandle, useState } from "react";
import { FormAutoCompleteDropdownField } from "./FormAutoCompleteDropdownField";
import FormHeader from "./FormHeader";
import { FormInputField, FormMultipleInputsInLine } from "./FormInputField";
import { FormDividerHeader } from "./FormDividerHeader";
import { FormButton } from "./FormButton";

import { ContactForm } from "./DynamicInputForm/ContactForm";
import { FormFileUploadField } from "./FormFileUploadField";

const Form = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    resetForm() {
      reset();
    },
    setValue(key, value) {
      setValue(key, value);
    },
  }));

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors: validationErrors },
  } = useForm();

  const formProps = {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    validationErrors,
  };

  const { title, isLoading, handleSave, handleAddFile, handleFileUpload, fileUploadButtonTitle } =
    props;
  const { inputFields, mode } = props;

  return (
    <Grid flex={1} justifyContent="center" mt={6} mb={2} container spacing={1}>
      <Grid item xs={12} md={12} xl={8}>
        <Card>
          <FormHeader title={title} />
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              {inputFields.map((metaData) => {
                const { componentType } = metaData;
                if (componentType === "input") {
                  return renderInputComponent({ formProps, mode, metaData });
                }
                if (componentType === "multipleInputsInOneLine") {
                  return (
                    <FormMultipleInputsInLine
                      mode={mode}
                      setValue={setValue}
                      control={control}
                      register={register}
                      validationErrors={validationErrors}
                      metaData={metaData}
                    />
                  );
                }
                if (componentType === "dropdown") {
                  return (
                    <FormAutoCompleteDropdownField
                      reset={reset}
                      mode={mode}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      metaData={metaData}
                      validationErrors={validationErrors}
                      register={register}
                    />
                  );
                }
                if (componentType === "dividerHeader") {
                  return renderDivider(metaData.dividerTitle);
                }
                if (componentType === "dynamicInputFormMultipleRows") {
                  return (
                    <ContactForm
                      reset={reset}
                      mode={mode}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      metaData={metaData}
                      validationErrors={validationErrors}
                      register={register}
                    />
                  );
                }
                if (componentType === "uploadFile" && mode !== "edit") {
                  return metaData.documentTypes?.map((d, index) => {
                    const { documentHasExpirationDate } = d;
                    const newMetaData = {
                      ...metaData,
                      fieldName: `documents.${index}`,
                      docTypeField: `documents.${index}.docType`,
                      docType: d.id,
                    };
                    return (
                      <>
                        {renderDivider(`Evrak yükle(max 3 mb): ${d.name}`)}
                        <MDBox
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                          mb={2}
                          mt={2}
                        >
                          <FormFileUploadField
                            metaData={newMetaData}
                            mode={mode}
                            setValue={setValue}
                            control={control}
                            register={register}
                            validationErrors={validationErrors}
                            label="Dosya Yükle"
                          />
                          {documentHasExpirationDate &&
                            renderInputComponent({
                              formProps,
                              mode,
                              metaData: {
                                fieldName: `documents.${index}.expiryDate`,
                                label: "Bitis Tarihi",
                                isFullWidth: true,
                                isRequired: false,
                                type: "date",
                              },
                            })}
                        </MDBox>
                      </>
                    );
                  });
                }
              })}
              <MDBox
                mt={4}
                sx={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                mb={1}
              >
                <FormButton
                  handleSave={handleSave}
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
});

const renderInputComponent = (data) => {
  const {
    mode,
    metaData,
    formProps: { setValue, control, register, validationErrors },
  } = data;
  return (
    <FormInputField
      mode={mode}
      setValue={setValue}
      control={control}
      register={register}
      validationErrors={validationErrors}
      metaData={metaData}
    />
  );
};

const renderDivider = (dividerTitle) => <FormDividerHeader dividerTitle={dividerTitle} />;

export default Form;
